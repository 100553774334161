const rijenParamNaarZoekvelden = function(rijenParam) {

  var zoekveldrijen = [];

  var rijenParamArr = rijenParam.split('|');

  var teller = 1;

  rijenParamArr.forEach((rijParam) => {

    var zoekveldrij = {};

    zoekveldrij.veldrijId = teller;
    teller++;

    var rijParamArr = rijParam.split('-');

    switch (rijParamArr[0]) {
      case 'O':
        zoekveldrij.veldOperator = 'Of';
        break;
      case 'E':
        zoekveldrij.veldOperator = 'En';
        break;
      case 'N':
        zoekveldrij.veldOperator = 'En niet';
        break;
      default:
        zoekveldrij.veldOperator = '';
        break;    
    };

    switch (rijParamArr[1]) {
      case 'at':
        zoekveldrij.veldType = 'Auteur';
        break;
      case 'ti':
        zoekveldrij.veldType = 'Titel';
        break;
      case 'ca':
        zoekveldrij.veldType = 'Categorie';
        break;
      case 'ts':
        zoekveldrij.veldType = 'Tijdschrift';
        break;
      case 'bl':
        zoekveldrij.veldType = 'Bijbellocatie';
        break;
      case 'bb':
        zoekveldrij.veldType = 'Bijbelboek';
        break;
      case 'sw':
        zoekveldrij.veldType = 'Sleutelwoord';
        break;
      case 'jr':
        zoekveldrij.veldType = 'Jaar';
        break;
      default:
        break;
    }

    zoekveldrij.veldRelatie = rijParamArr[2];

    if(zoekveldrij.veldType === 'Bijbellocatie') {
      var bijbellocatieParamArr = rijParamArr[3].split(';');
      zoekveldrij.veldWaarde = {
        bijbelboekId: parseInt(bijbellocatieParamArr[0]),
        hoofdstukBegin: parseInt(bijbellocatieParamArr[1]),
        versBegin: parseInt(bijbellocatieParamArr[2]),
        hoofdstukEinde: parseInt(bijbellocatieParamArr[3]),
        versEinde: parseInt(bijbellocatieParamArr[4]),
      };
    } else if (zoekveldrij.veldType === 'Titel' || zoekveldrij.veldType === 'Auteur') {
      zoekveldrij.veldWaarde = rijParamArr[3].replaceAll('+', ' ');
    } else {
      zoekveldrij.veldWaarde = parseInt(rijParamArr[3]);
    }

    zoekveldrijen.push(zoekveldrij);

  });

  return zoekveldrijen;
  
};

export default rijenParamNaarZoekvelden;