import React from 'react';
import { FormControl, MenuItem, Select, TextField, IconButton } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Bijbellocatie from './Bijbellocatie';
import Categorie from './Categorie';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

class Zoekveldrij extends React.Component {

  constructor(props) {
    super(props);
    this.state = { bigscreen: window.matchMedia("(min-width: "+window.theme.breakpoints.width('sm')+"px)").matches };
  }

  componentDidMount() {
    const handler = e => this.setState({bigscreen: e.matches});
    window.matchMedia("(min-width: "+window.theme.breakpoints.width('sm')+"px)").addEventListener("change", handler);
  }

  render() {
  
   var operatorOpties = this.props.lijstOperatoren.map(function(operator) { 
      return (<MenuItem value={operator} key={operator}>{operator}</MenuItem>)
    });

    var typeOpties = this.props.lijstTypen.map(function(type) { 
      return (<MenuItem value={type} key={type}>{type}</MenuItem>)
    });

    var tijdschriftOpties = this.props.lijstTijdschriften.map(function(tijdschrift) { 
      return (<MenuItem value={tijdschrift.tijdschriftId} key={tijdschrift.tijdschriftId}>{tijdschrift.naam}</MenuItem>)
    });

   const jaarOpties = [];
   var currentJaar;
   var huidigeJaar = new Date().getFullYear();
   var minJaar=this.props.jaren.minJaar<1950 ? 1950 : this.props.jaren.minJaar;
   var maxJaar=this.props.jaren.maxJaar>huidigeJaar ? huidigeJaar : this.props.jaren.maxJaar;
   for(currentJaar = minJaar; currentJaar <= maxJaar; currentJaar++) {
    jaarOpties.push(<MenuItem key={currentJaar} value={currentJaar}>{currentJaar}</MenuItem>);
   }

    //Afhandeling select-node voor veldOperator. Wordt niet getoond indien er maar 1 veldrij is
    var veldOperator;
    if (this.props.eersteVeldrij) {
      veldOperator =<span></span>;
    }
    else {
      veldOperator =
        <FormControl>
          <MuiThemeProvider theme={window.redSelectTheme}>         
            <Select 
              name={"veldOperator-"+this.props.zoekveldrij.veldrijId}
              onChange={this.props.onChangeOperator}
              value={this.props.zoekveldrij.veldOperator}
            >
              {operatorOpties}
            </Select>
          </MuiThemeProvider> 
        </FormControl>
    }

    //De node voor veldRelatie is afhankelijk van de waarde van veldType
    var veldRelatie;
    if (this.props.zoekveldrij.veldType==="Tijdschrift" || this.props.zoekveldrij.veldType==="Sleutelwoord") {
      veldRelatie = <span>is</span>;
    }
    else if (this.props.zoekveldrij.veldType==="Jaar") {
      veldRelatie = 
        <FormControl>
          <MuiThemeProvider theme={window.purpleSelectTheme}>         
            <Select
              name={"veldRelatie-"+this.props.zoekveldrij.veldrijId}
              onChange={this.props.onChangeRelatie}
              value={this.props.zoekveldrij.veldRelatie}
              color="primary"
              >
              <MenuItem value={1} key={1}>van</MenuItem>
              <MenuItem value={2} key={2}>tot</MenuItem>
              <MenuItem value={3} key={3}>is</MenuItem>
            </Select>
          </MuiThemeProvider>
        </FormControl>
    }
    else {
      veldRelatie = <span>bevat</span>;
    }

    //De node voor veldWaarde is afhankelijk van de waarde van veldType
    var veldWaarde; 
    if (this.props.zoekveldrij.veldType==="Tijdschrift") {
      veldWaarde =
        <FormControl>
          <Select 
            name={"veldWaarde-"+this.props.zoekveldrij.veldrijId} 
            onChange={(event) => this.props.onChangeWaarde("veldWaarde-"+this.props.zoekveldrij.veldrijId, this.props.zoekveldrij.veldType, event.target.value, event.currentTarget)}
            value={this.props.zoekveldrij.veldWaarde}
            style={{ width: this.state.bigscreen ? 270 : 100}}
            >
            {tijdschriftOpties}
          </Select>
        </FormControl>
    }
    else if (this.props.zoekveldrij.veldType==="Sleutelwoord") {

      var sleutelwoordCurValueOption = null;
      this.props.lijstSleutelwoorden.forEach(sleutelwoord => {
        if(Number(sleutelwoord.sleutelwoordId) === Number(this.props.zoekveldrij.veldWaarde)) {
          sleutelwoordCurValueOption = sleutelwoord
        }
      });

      veldWaarde =
        <FormControl>
          <Autocomplete
            key={"veldWaardA-"+this.props.zoekveldrij.veldrijId}
            id={"veldWaardA-"+this.props.zoekveldrij.veldrijId}
            onChange={(event, value) => this.props.onChangeWaarde("veldWaardA-"+this.props.zoekveldrij.veldrijId, this.props.zoekveldrij.veldType, value, event.currentTarget)}
            options={this.props.lijstSleutelwoorden}
            getOptionLabel={(option) => option.sleutelwoord}
            getOptionSelected={(option, value) => {return option.sleutelwoordId === value.sleutelwoordId}}
            noOptionsText="Geen sleutelwoord"
            style={{ width: this.state.bigscreen ? 270 : 100}}
            autoComplete
            renderInput={(params) => <TextField {...params} id={"Textfield-"+this.props.zoekveldrij.veldrijId}/>}
            value={sleutelwoordCurValueOption}
          />
        </FormControl>
    }
    else if (this.props.zoekveldrij.veldType==="Jaar") {
      veldWaarde =
        <FormControl>
          <Select 
            name={"veldWaarde-"+this.props.zoekveldrij.veldrijId} 
            onChange={(event) => this.props.onChangeWaarde("veldWaarde-"+this.props.zoekveldrij.veldrijId, this.props.zoekveldrij.veldType, event.target.value, event.currentTarget)}
            value={this.props.zoekveldrij.veldWaarde}
            style={{ width: this.state.bigscreen ? 270 : 100}}
            >
              {jaarOpties}
          </Select>
        </FormControl>
    }
    else if (this.props.zoekveldrij.veldType==="Bijbelboek") {

      var bijbelboekCurValueOption = null;
      this.props.lijstBijbelboeken.forEach(bijbelboek => {
        if(Number(bijbelboek.bijbelboekId) === Number(this.props.zoekveldrij.veldWaarde)) {
          bijbelboekCurValueOption = bijbelboek
        }
      });

      veldWaarde =
        <FormControl>
          <Autocomplete
            key={"veldWaardB-"+this.props.zoekveldrij.veldrijId}
            id={"veldWaardB-"+this.props.zoekveldrij.veldrijId}
            onChange={(event, value) => this.props.onChangeWaarde("veldWaardB-"+this.props.zoekveldrij.veldrijId, this.props.zoekveldrij.veldType, value, event.currentTarget)}
            options={this.props.lijstBijbelboeken}
            getOptionLabel={(option) => option.naam}
            getOptionSelected={(option, value) => {return option.bijbelboekId === value.bijbelboekId}}
            style={{ width: this.state.bigscreen ? 270 : 100}}
            filterOptions={createFilterOptions({matchFrom: 'start'})}
            noOptionsText="Geen Bijbelboek"
            autoComplete
            renderInput={(params) => <TextField {...params} id={"Textfield-"+this.props.zoekveldrij.veldrijId}/>}
            value={bijbelboekCurValueOption}
          />
        </FormControl>
    }
    else if (this.props.zoekveldrij.veldType==="Bijbellocatie") {
      veldWaarde = <Bijbellocatie 
        onChangeWaarde={this.props.onChangeWaarde}
        lijstBijbelboeken={this.props.lijstBijbelboeken}
        zoekveldrij={this.props.zoekveldrij}
        />
    }
    else if (this.props.zoekveldrij.veldType==="Categorie") {
      veldWaarde = <Categorie 
        onChangeWaarde={this.props.onChangeWaarde}
        lijstCategorieen={this.props.lijstCategorieen}
        zoekveldrij={this.props.zoekveldrij}
        />
    }
    else {
      //veldType = Auteur, Titel
      veldWaarde =
          <FormControl>
          <TextField 
            type='text' 
            name={"veldWaarde-"+this.props.zoekveldrij.veldrijId} 
            onChange={(event) => this.props.onChangeWaarde("veldWaarde-"+this.props.zoekveldrij.veldrijId, this.props.zoekveldrij.veldType, event.target.value, event.currentTarget)} 
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.onEnterKey();
              }
           }}
            value={this.props.zoekveldrij.veldWaarde}
            style={{ width: this.state.bigscreen ? 270 : 100}}
          />
        </FormControl>
    }
 
    //Afhandeling button voor toevoegen van rij (deleteButton). 
    //Wordt bij elke rij getoond indien aantal regels groter is dan 1
    var deleteButton;
    if (this.props.aantalVeldrijen > 1) {
      deleteButton =
        <MuiThemeProvider theme={window.purpleTheme}>
          <IconButton 
            onClick={(event) => this.props.onRowDel("deleteButton-"+this.props.zoekveldrij.veldrijId)}
            style={{padding : 0}}
            color="primary"
            >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </MuiThemeProvider>
    }
    else {
      deleteButton =<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
    }

    //Afhandeling button voor toevoegen van rij (addButton). 
    //Wordt alleen getoond op de laatste veldrij
    var addButton;
    if (this.props.laatsteVeldrij) {
      addButton =
        <MuiThemeProvider theme={window.purpleTheme}>
          <IconButton 
            onClick={(event) => this.props.onRowAdd("addButton-"+this.props.zoekveldrij.veldrijId)}
            style={{padding : 0}}
            color="primary"
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </MuiThemeProvider>
    }
    else {
      addButton ='';
    }
 
    return (
      <tr>
        <td width="15%">
          {veldOperator}
        </td>
        <td width="20%">
          <FormControl>
            <MuiThemeProvider theme={window.redSelectTheme}>         
              <Select 
                name={"veldType-"+this.props.zoekveldrij.veldrijId} 
                onChange={this.props.onChangeType} 
                value={this.props.zoekveldrij.veldType}
                color="primary"
              >
                {typeOpties}
              </Select>
            </MuiThemeProvider>
          </FormControl>
        </td>
        <td width="10%">
          {veldRelatie}
        </td>
        <td width="40%">
          {veldWaarde}
        </td>
        <td padding="0" width="30" align="right">
          {deleteButton}
        </td>
        <td padding="0" width="30">
          {addButton}
        </td>
      </tr>
    );

  }

}

export default Zoekveldrij;