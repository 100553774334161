import React from 'react';
import ZoekResultaat from './ZoekResultaat';
import { Box, CircularProgress, Grid, IconButton, Tooltip, Popover, Divider } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Pagination from '@material-ui/lab/Pagination';
import {Button} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {MenuItem, Select} from '@material-ui/core';
import rijenParamNaarZoekvelden from './functies';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CancelIcon from "@material-ui/icons/Cancel";

class ZoekResultaten extends React.Component {

  constructor(props) {
    super(props);

    this.lijstArtikelenPerPagina = [
      { value: 2, label : "2 / pag"},
      { value: 5, label : "5 / pag"},
      { value: 10, label : "10 / pag"},
      { value: 20, label : "20 / pag"},
      { value: 40, label : "40 / pag"}
    ];
    this.lijstSorteerOpties = [
      { id: "auteur", label : "auteur"},
      { id: "titel", label : "titel"},
      { id: "tijdschrift", label : "tijdschrift"},
      { id: "jaarOplopend", label : "jaar (oplopend)"},
      { id: "jaarAflopend", label : "jaar (aflopend)"}
    ];

    this.clientSleutel="";

    this.state = {
      totaalAantalZoekresultaten: 0,
      paginaZoekresultaten: 1,
      aantalPaginasZoekresultaten: 1,
      aantalZoekresultatenPerPagina : 10,
      sorteerOptie: "jaarAflopend",
      zoekresultaten: [],
      zoektermenResultaat:[],
      zoektermenTonen:[],
      resultatenGezocht: false,
      isLoadingZoekresultaten: false,
      popoverRISExportOpen: false,
      anchorElRISExport: null,
      rijenRISExport:[],
      selectAll: true,
    };
  }

  componentDidMount() {
    
    //Bepaal clientsleutel
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      this.clientSleutel = "qfOORX9YyFxkGA5SbK9MiCP9Vczdu0z8";
    } else {
      this.clientSleutel = window.client_sleutel;
    }


    if(this.props.match.params.termen || this.props.match.params.rijen) {
      this.updateZoekresultaten();
    }

  }

  componentDidUpdate(prevProps) {
  
    var curParams = this.props.match.params;
    var prevParams = prevProps.match.params;

    var fetchOpnieuw = curParams.termen !== prevParams.termen;
    fetchOpnieuw = fetchOpnieuw || curParams.rijen !== prevParams.rijen;
    fetchOpnieuw = fetchOpnieuw || curParams.aantalperpagina !== prevParams.aantalperpagina;
    fetchOpnieuw = fetchOpnieuw || curParams.aantalperpagina !== prevParams.aantalperpagina;
    fetchOpnieuw = fetchOpnieuw || curParams.pagina !== prevParams.pagina;
    fetchOpnieuw = fetchOpnieuw || curParams.onlineartikelen !== prevParams.onlineartikelen;
    fetchOpnieuw = fetchOpnieuw || curParams.geenrecensies !== prevParams.geenrecensies;
    fetchOpnieuw = fetchOpnieuw || curParams.sorteeroptie !== prevParams.sorteeroptie;

    if(fetchOpnieuw) {
      this.updateZoekresultaten();
    }
  }

  updateZoekresultaten() {
    var curParams = this.props.match.params;

    var aantalperpagina = this.state.aantalZoekresultatenPerPagina;

    if(curParams.aantalperpagina) {
      this.setState({aantalZoekresultatenPerPagina: parseInt(curParams.aantalperpagina)});
      aantalperpagina = curParams.aantalperpagina;
    } else {
      this.setState({aantalZoekresultatenPerPagina: 10});
      aantalperpagina = 10;
    }

    var sorteeroptie = this.state.sorteerOptie;

    if(curParams.sorteeroptie) {
      this.setState({sorteerOptie: curParams.sorteeroptie});
      sorteeroptie = curParams.sorteeroptie;
    } else {
      this.setState({sorteerOptie: "jaarAflopend"});
      sorteeroptie = "jaarAflopend";
    }

    var pagina = this.state.paginaZoekresultaten;

    if(curParams.pagina) {
      this.setState({paginaZoekresultaten: parseInt(curParams.pagina)});
      pagina = curParams.pagina;
    } else {
      this.setState({paginaZoekresultaten: 1});
      pagina = 1;
    }

    var onlineartikelen = parseInt(curParams.onlineartikelen);

    var geenrecensies = parseInt(curParams.geenrecensies);

    var vervolgZoekactie = parseInt(curParams.vervolgzoekactie) ? true : false;

    if(this.props.location.pathname.indexOf("/eenvoudig") !== -1) {
      //verwijder quotes uit zoektermen
      var zoektermen = curParams.termen.split('|');
      var zoektermenNew = [];
      var zoekterm; 
      for (let i = 0; i < zoektermen.length; i++) {
        zoekterm=zoektermen[i].replace(/'/g, "");
        zoektermenNew.push(zoekterm);
      }  
      this.fetchArtikelenEenvoudig(zoektermenNew,vervolgZoekactie, pagina, aantalperpagina, onlineartikelen, geenrecensies, sorteeroptie);
      document.title = zoektermen;
    } else {

      var zoekveldrijen = [];

      if(this.props.match.params.rijen) {

        zoekveldrijen = rijenParamNaarZoekvelden(this.props.match.params.rijen);

        if (Number(onlineartikelen)===1) {
          var zoekveldrijOnlineArtikelen={     
            veldrijId: null,
            veldOperator : 'En',
            veldType : 'OnlineArtikel',
            veldRelatie : '',
            veldWaarde: ''
          }
          zoekveldrijen.push(zoekveldrijOnlineArtikelen);
        }

        if (Number(geenrecensies)===1) {
          var zoekveldrijGeenRecensies={     
            veldrijId: null,
            veldOperator : 'En',
            veldType : 'GeenRecensies',
            veldRelatie : '',
            veldWaarde: ''
          }
          zoekveldrijen.push(zoekveldrijGeenRecensies);
        }

      }
  
      this.fetchArtikelenGeavanceerd(zoekveldrijen,vervolgZoekactie, pagina, aantalperpagina, sorteeroptie);
      // stel titel en canonical url in
      this.props.zoekveldrijenNaarTitel();
      document.querySelector("link[rel='canonical']").setAttribute('href',window.location.href);
    }
  }

  fetchArtikelenEenvoudig(zoektermen,vervolgZoekactie, pagina, aantalResultatenPerPagina, onlineArtikelen, geenRecensies, sorteerOptie) {

    //Functie haalt artikelen op uit database op basis van zoekterm. Argumenten:
    //  zoektermen: array met zoektermen waarop gezocht wordt
    //  vervolgZoekactie : true: zoekactie op basis van combinatie zoektermen die van server zijn ontvangen
    //                   : false: zoekactie op basis van 
    //  pagina: pagina waarvoor zoekvelden moeten worden opgehaald
    //  aantalResultatenPerPagina
    //  onlineArtikelen : true: alleen artikelen die online beschikbaar zijn
    //  geenRecensies : true: alleen artikelen waarvan veld recensie=0
    //  sorteeroptie
    var json = JSON.stringify({
      zoektermen: zoektermen, 
      vervolgZoekactie: vervolgZoekactie,
      pagina: pagina, 
      aantalResultaten: aantalResultatenPerPagina, 
      onlineArtikelen: onlineArtikelen,
      geenRecensies: geenRecensies,
      sorteerOptie: sorteerOptie
    });

    //Bepaal controlecode
    const serviceNummer = 1832;
    var randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    var controleGetal = json.length * serviceNummer * randomGetal;
    var controleCode = randomGetal.toString() + controleGetal.toString();

    this.setState({
      isLoadingZoekresultaten: true,
      error: false,
      errorMessage: ""
    });

    fetch("/api/artikel/searchArtikelEenvoudig.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel, {method: 'POST', body: json})
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.records != "undefined") {
          var totaalAantalResultaten = result.totaalAantalResultaten;
          var aantalPaginasZoekresultaten = Math.floor((totaalAantalResultaten-1)/aantalResultatenPerPagina)+1;
          //Bepaal tonen zoektermen
          var zoektermenTonen=[];
          if (result.zoektermenResultaat!==null) {
            var zoektermenResultaat = [];
            zoektermenResultaat = result.zoektermenResultaat;
            if (this.props.match.params.termen.length>0) {
              var zoekveldEenvoudigZoeken = this.props.match.params.termen;
              var positieSpatieStart=0;
              var positieSpatie;
              var zoekterm="";
              var synoniemen;
              do {
                //Haal zoekterm uit zoekveldEenvoudigZoeken
                positieSpatie = zoekveldEenvoudigZoeken.indexOf("|",positieSpatieStart);
                if (positieSpatie > -1) {
                  zoekterm=zoekveldEenvoudigZoeken.substring(positieSpatieStart, positieSpatie);
                  positieSpatieStart=positieSpatie+1;
                } else if (positieSpatieStart<zoekveldEenvoudigZoeken.length) {
                  zoekterm=zoekveldEenvoudigZoeken.substring(positieSpatieStart, zoekveldEenvoudigZoeken.length);
                } 
                //Bepaal of zoekterm voorkomt in zoektermen van resultaat
                var zoektermGevonden=false;
                var zoektermResultaatArray= [];
                for (let i = 0; i < zoektermenResultaat.length; i++) {
                  zoektermResultaatArray = zoektermenResultaat[i];
                  //Het eerste element in de array bevat de zoekterm die naar de server is gestuurd
                  //zoekterm bevat mogelijk quotes, zoektermResultaatArray
                  if (zoekterm.replace(/'/g, "")===zoektermResultaatArray[0].zoekterm) {
                    zoektermGevonden=true;
                    //Zijn er synoniemen?
                    synoniemen="";
                    if (zoektermResultaatArray.length>1) {
                      for (let j = 1; j < zoektermResultaatArray.length; j++) {
                        if (Number(zoektermResultaatArray[j].zichtbaar)===1) {
                          if (synoniemen!=="") {synoniemen=synoniemen+" ";}
                          synoniemen=synoniemen+zoektermResultaatArray[j].zoekterm;
                        }
                      }
                    }
                  }
                }
                
                if (!zoektermGevonden) {
                  zoekterm=
                    <MuiThemeProvider theme={window.tekstZoektermTheme} key={zoekterm}>
                      <Typography variant="body1" display="inline" color="textPrimary">
                        <span><span style={{color:"red", textDecorationLine: 'line-through'}}><span style={{color:"black"}}>{zoekterm}</span></span> </span>
                      </Typography>
                    </MuiThemeProvider>
                } else {
                    if (synoniemen!=="") {
                      zoekterm=
                        <MuiThemeProvider theme={window.tekstZoektermTheme} key={zoekterm}>
                          <Typography variant="body1" display="inline" color="textPrimary">{zoekterm+" ("}</Typography>
                          <Typography variant="body1" display="inline" color="textSecondary">{synoniemen}</Typography>
                          <Typography variant="body1" display="inline" color="textPrimary">)</Typography>
                        </MuiThemeProvider>
                    }
                    //zoekterm=zoekterm+" ";
                }
                zoektermenTonen.push(zoekterm);            
                zoektermenTonen.push(" ");            
              } while (positieSpatie > -1);
            }
          }
          this.setState({
            zoekresultaten: result.records,
            totaalAantalZoekresultaten: totaalAantalResultaten,
            aantalPaginasZoekresultaten: aantalPaginasZoekresultaten,
            zoektermenResultaat: result.zoektermenResultaat,
            zoektermenTonen: zoektermenTonen,
            resultatenGezocht: true,
            isLoadingZoekresultaten: false
          });
        }
        else if (typeof result.message != "undefined") {
          var errorMessage;
          if (result.message.substr(0, 4)=== "Fout") {
            errorMessage= "Er gaat iets mis bij het ophalen van artikelen.";
          }
          else {
            errorMessage= result.message;
          }

          this.setState({
            zoekresultaten: [],
            zoektermenResultaat: [],
            zoektermenTonen:[],
            resultatenGezocht: true,
            isLoadingZoekresultaten: false,
            errorMessage: errorMessage,
            error: true
          });
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van artikelen."
          });        
        }
      },
      (error) => {
        this.setState({
        error
        });
      }
    );
  }

  fetchArtikelenGeavanceerd(zoekveldrijen, vervolgZoekactie, pagina, aantalResultatenPerPagina, sorteerOptie) {

    //Functie haalt artikelen op uit database op basis van zoekveldrijen. 
    //Argumenten:
    //  zoekveldrijen: zoekvelden en criteria waarop gezocht wordt
    //  pagina: pagina waarvoor zoekvelden moeten worden opgehaald
    //  aantalResultatenPerPagina
    //  sorteerOptie
    var json = JSON.stringify({
      zoekveldrijen: zoekveldrijen, 
      vervolgZoekactie: vervolgZoekactie,
      pagina: pagina, aantalResultaten: 
      aantalResultatenPerPagina, 
      sorteerOptie: sorteerOptie
    });

    //Bepaal controlecode voor server
    const serviceNummer = 1946;
    var randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    var controleGetal = json.length * serviceNummer * randomGetal;
    var controleCode = randomGetal.toString() + controleGetal.toString();

    this.setState({isLoadingZoekresultaten: true});

    var url = "/api/artikel/searchArtikelGeavanceerd.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel;

    fetch(url, {method: 'POST', body: json})
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.records != "undefined") {
          var totaalAantalResultaten = result.totaalAantalResultaten;
          var aantalPaginasZoekresultaten = Math.floor((totaalAantalResultaten-1)/aantalResultatenPerPagina)+1;
          this.setState({
            zoekresultaten: result.records,
            totaalAantalZoekresultaten: totaalAantalResultaten,
            aantalPaginasZoekresultaten: aantalPaginasZoekresultaten,
            resultatenGezocht: true,
            isLoadingZoekresultaten: false
          });
        }
        else if (typeof result.message != "undefined") {
          var errorMessage;
          if (result.message.substr(0, 4)=== "Fout") {
            errorMessage= "Er gaat iets mis bij het ophalen van artikelen.";
          }
          else {
            errorMessage= result.message;
          }
          this.setState({
            zoekresultaten: [],
            resultatenGezocht: true,
            isLoadingZoekresultaten: false,
            errorMessage: errorMessage,
            error: true
          });
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van artikelen."
          });
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het ophalen van artikelen."
        });
      }
    );
  }

  fetchExport(artikelIds) {
    //Functie expoteert zoekresultaten naar Ris-bestand
    var json = JSON.stringify({
      artikelIds: artikelIds
    });

    //Bepaal controlecode voor server
    const serviceNummer = 2718;
    var randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    var controleGetal = json.length * serviceNummer * randomGetal;
    var controleCode = randomGetal.toString() + controleGetal.toString();

    //this.setState({isLoadingZoekresultaten: true});

    fetch("/api/artikel/exportRIS.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel, {method: 'POST', body: json})
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.bestand != "undefined") {
          //Download bestand
          window.open(result.bestand);
        }
        else if (typeof result.message != "undefined") {
          var errorMessage;
          if (result.message.substr(0, 4)=== "Fout") {
            errorMessage= "Er gaat iets mis bij het exporteren van artikelen.";
          }
          else {
            errorMessage= result.message;
          }
          this.setState({
            errorMessage: errorMessage,
            error: true
          });
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het exporteren van artikelen."
          });
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het exporteren van artikelen."
        });
      }
    );
  }

  handleTopButton() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  handleChangeZoekresultatenPerPagina(event) {

    var aantalZoekresultatenPerPagina =event.target.value;

    var newUrl = '';

    if(this.props.location.pathname.indexOf('geavanceerd') !== -1) {
      newUrl = '/geavanceerd/zoeken/' + this.props.match.params.rijen +"/"+this.props.match.params.onlineartikelen +"/"+this.props.match.params.geenrecensies+"/1/"+this.state.sorteerOptie+'/'+aantalZoekresultatenPerPagina+"/"+this.state.paginaZoekresultaten;
    } else {
      newUrl = '/eenvoudig/zoeken/' + this.props.match.params.termen +"/"+this.props.match.params.onlineartikelen+ "/"+this.props.match.params.geenrecensies+"/1/"+this.state.sorteerOptie+'/'+aantalZoekresultatenPerPagina+"/"+this.state.paginaZoekresultaten;
    }

    this.props.history.push(newUrl);
  }

  handleChangeSorteerOptie(event) {
  
    //Functie handelt wijzing af van SorteerOptie
    var sorteerOptie =event.target.value;

    var newUrl = '';

    if(this.props.location.pathname.indexOf('geavanceerd') !== -1) {
      newUrl = '/geavanceerd/zoeken/' + this.props.match.params.rijen +"/"+this.props.match.params.onlineartikelen+ "/"+this.props.match.params.geenrecensies+"/1/"+sorteerOptie+'/'+this.state.aantalZoekresultatenPerPagina+"/"+this.state.paginaZoekresultaten;
    } else {
      newUrl = '/eenvoudig/zoeken/' + this.props.match.params.termen +"/"+this.props.match.params.onlineartikelen +"/"+this.props.match.params.geenrecensies+"/1/"+sorteerOptie+'/'+this.state.aantalZoekresultatenPerPagina+"/"+this.state.paginaZoekresultaten;
    }

    this.props.history.push(newUrl);
  }  

  handleChangePagina(event, value) {
    var paginaZoekresultaten= value;

    var newUrl = '';

    if(this.props.location.pathname.indexOf('geavanceerd') !== -1) {
      newUrl = '/geavanceerd/zoeken/' + this.props.match.params.rijen +"/"+this.props.match.params.onlineartikelen +"/"+this.props.match.params.geenrecensies+"/1/"+this.state.sorteerOptie+'/'+this.state.aantalZoekresultatenPerPagina+"/"+paginaZoekresultaten;
    } else {
      newUrl = '/eenvoudig/zoeken/' + this.props.match.params.termen +"/"+this.props.match.params.onlineartikelen +"/"+this.props.match.params.geenrecensies+"/1/"+this.state.sorteerOptie+'/'+this.state.aantalZoekresultatenPerPagina+"/"+paginaZoekresultaten;
    }

    this.props.history.push(newUrl);

    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  handleExport() {
    var artikelIds = [];
    for (let i = 0; i < this.state.rijenRISExport.length; i++) {
      if (this.state.rijenRISExport[i].geselecteerd) {
        artikelIds.push(this.state.rijenRISExport[i].artikelId);
      }
    }
    this.fetchExport(artikelIds);
    this.setState({
      popoverRISExportOpen: false,
    });
  }

  handleOpenPopoverRISExport(event) {
    var rijenRISExport = [];
    var rijnummer=(this.state.paginaZoekresultaten-1)*this.state.aantalZoekresultatenPerPagina+1;
    var artikelRISExport;
    var jaargang;
    var nummer;
    var paginaStart;
    var paginaEinde;
    this.state.zoekresultaten.forEach((artikel) => {
      jaargang=artikel.jaargang !== null ? artikel.jaargang.length>0 ? " "+artikel.jaargang+" " : "" : "";
      nummer=artikel.nummer !== null ? artikel.nummer.length>0 ? "nr "+artikel.nummer : "" : "";
      paginaStart=artikel.paginaStart !== null ? artikel.paginaStart.length>0 ? ", p "+artikel.paginaStart : "" : "";
      paginaEinde=artikel.paginaEinde !== null ? artikel.paginaEinde.length>0 ? "-"+artikel.paginaEinde : "" : "";
      artikelRISExport = {
        rijnummer : rijnummer,
        geselecteerd : true,
        artikelId : artikel.artikelId,
        artikelAuteurs : artikel.auteurs,
        artikelTitel : artikel.titel,
        artikelTijdschrift : artikel.tijdschrift,
        artikelTijdschriftInfo : jaargang+nummer+paginaStart+paginaEinde
      };
      rijenRISExport.push(artikelRISExport);
      rijnummer++;
    })   
    this.setState({
      popoverRISExportOpen: true,
      anchorElRISExport: event.currentTarget,
      rijenRISExport : rijenRISExport
    });
  }

  handleOnclickCancelRISExport(event) {
    this.setState({
      popoverRISExportOpen: false,
    });
  }

  handleOnclickSelectAll(event) {
    var newRijenRISExport=this.state.rijenRISExport.map((rijRISExport) => {
      rijRISExport.geselecteerd=!this.state.selectAll;
      return rijRISExport;
    })

    this.setState({
      selectAll: !this.state.selectAll,
      rijenRISExport: newRijenRISExport,
    });

  }

  handleOnclickSelect(artikelId) {
    var selectAll=true;
    var newRijenRISExport=this.state.rijenRISExport.map((rijRISExport) => {
      if (rijRISExport.artikelId===artikelId) {
        rijRISExport.geselecteerd=!rijRISExport.geselecteerd;
      }
      if (!rijRISExport.geselecteerd) {selectAll=false}
      return rijRISExport;
    })
    this.setState({
      rijenRISExport: newRijenRISExport,
      selectAll: selectAll,
    });  
  }

  render() {

    if (this.state.zoekresultaten.length > 0) {
      //Bereid artikelen per pagina voor
      //Bereid sorteerOpties voor
      var sorteerOpties = this.lijstSorteerOpties.map(function(SorteerOptie) { 
        return (<MenuItem value={SorteerOptie.id} key={SorteerOptie.id}>{SorteerOptie.label}</MenuItem>)  
      });
      
    } else if (this.state.resultatenGezocht) {
      //artikelrange ="Geen artikelen gevonden";
    }

    if(this.state && !this.state.isLoadingZoekresultaten) {

      var artikelrange ="";
      var paging ="";  

      //Bereid artikelrange voor
      var artikelrangeStart =(this.state.paginaZoekresultaten-1)*this.state.aantalZoekresultatenPerPagina+1;
      var artikelrangeEinde =
        this.state.paginaZoekresultaten*this.state.aantalZoekresultatenPerPagina > this.state.totaalAantalZoekresultaten ?
        this.state.totaalAantalZoekresultaten : this.state.paginaZoekresultaten*this.state.aantalZoekresultatenPerPagina;
      artikelrange=<span>{artikelrangeStart} - {artikelrangeEinde} van {this.state.totaalAantalZoekresultaten} artikelen</span>;

      if (Number(this.state.totaalAantalZoekresultaten) > Number(this.state.aantalZoekresultatenPerPagina)) {
        paging =
        <Pagination
              className="my-3"
              count={this.state.aantalPaginasZoekresultaten}
              page={this.state.paginaZoekresultaten}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={this.handleChangePagina.bind(this)}
              size="small"
              showFirstButton={true}
              showLastButton={true}
            />;
      }

      var popoverWidth;
      if (window.innerWidth>850) {
        popoverWidth='850px';
      }
      else {
        popoverWidth=(window.innerWidth*0.75).toString() + 'px';
      }

      return (
        
        <React.Fragment>
          {this.props.location.pathname.indexOf("/eenvoudig") !== -1 && this.state.zoektermenTonen.length>0
            ? <div style={{marginTop:30}}>
                <span>Zoektermen: {this.state.zoektermenTonen}</span>
              </div>
            : ""
          }
          {this.state.zoekresultaten.length>0 ? 
          <div style={{marginTop : 40}}>
            <div style={{paddingRight: 20}}>
            <Grid container>
              <Grid item sm={12} md={9}>
                    <MuiThemeProvider theme={window.tekstGrootTheme}>
                      <Typography variant="h1" display="inline" color="textPrimary">Uw zoekactie heeft </Typography> 
                      <Typography variant="h1" display="inline" color="textSecondary">{this.state.totaalAantalZoekresultaten}</Typography>
                      <Typography variant="h1" display="inline" color="textPrimary">  artikelen opgeleverd</Typography>
                    </MuiThemeProvider>
              </Grid>
              <Grid item sm={12} md={3} align="right">
                <table>
                  <tbody>
                    <tr>
                      <td align="right">
                        <MuiThemeProvider theme={window.redSelectTheme}>        
                          <Select 
                            onChange={this.handleChangeZoekresultatenPerPagina.bind(this)} 
                            value={this.state.aantalZoekresultatenPerPagina>40 ? 40 : this.state.aantalZoekresultatenPerPagina}
                          >
                            {this.lijstArtikelenPerPagina.map(function(aantalArtikelenPerPagina) { 
                              return (<MenuItem value={aantalArtikelenPerPagina.value} key={aantalArtikelenPerPagina.value}>{aantalArtikelenPerPagina.label}</MenuItem>)  
                            })}
                          </Select>
                        </MuiThemeProvider>
                        </td>
                      <td align="right">
                        <MuiThemeProvider theme={window.redSelectTheme}>        
                          <Select 
                              onChange={this.handleChangeSorteerOptie.bind(this)} 
                              value={this.state.sorteerOptie}
                          >
                            {sorteerOpties}
                          </Select>
                        </MuiThemeProvider>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            </div>
            <div>
              <Box style={{marginTop : 40}}>
                {this.state.zoekresultaten.map((zoekresultaat,index) => { 
                  return (<ZoekResultaat
                    key={zoekresultaat.artikelId}
                    zoekresultaat={zoekresultaat}
                    nummerZoekresultaat={artikelrangeStart+index}
                    fetchExport={this.fetchExport.bind(this)}
                    />);
                })}
              </Box>
            </div>
            <div style={{marginTop : 20}}/>
              <table style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <td align="left">
                      {artikelrange}
                    </td>
                    <td align="center">
                      {paging}
                    </td>
                    <td align="right">
                      {this.state.zoekresultaten.length>0 
                        ? 
                        <span>                  
                          <MuiThemeProvider theme={window.purpleTheme}>
                            <Button 
                              variant="contained" 
                              color="primary"
                              onClick={this.handleTopButton.bind(this)} 
                              style={{textTransform: 'none', borderRadius: 0}}
                              endIcon={
                                <MuiThemeProvider theme={window.orangeTheme}>
                                  <ArrowDropUpIcon
                                    color="primary"
                                  />
                                </MuiThemeProvider>
                              }
                            >
                              Naar boven
                            </Button>
                          </MuiThemeProvider>
                          <span style={{marginLeft: 20}} />
                          <MuiThemeProvider theme={window.purpleTheme}>
                            <Button 
                              variant="contained" 
                              color="primary"
                              onClick={this.handleOpenPopoverRISExport.bind(this)}
                              style={{textTransform: 'none', borderRadius: 0}}
                              endIcon={
                                <MuiThemeProvider theme={window.orangeTheme}>
                                  <ExitToAppIcon
                                    color="primary"
                                  />
                                </MuiThemeProvider>
                              }
                            >
                              RIS Export
                            </Button>
                          </MuiThemeProvider>
                          <Popover
                            open={this.state.popoverRISExportOpen}
                            anchorEl={this.state.anchorElRISExport}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                          >
                            <div style={{ width: popoverWidth}}>
                              <MuiThemeProvider theme={window.purpleTheme}>
                                <IconButton
                                  onClick={this.handleOnclickSelectAll.bind(this)}
                                  style={{ marginLeft: 0 }}
                                  color="primary"
                                >
                                  {this.state.selectAll ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                                </IconButton>
                              </MuiThemeProvider>
                              {<span style={{ paddingLeft: 10 }}>{this.state.selectAll ? "Deselecteer " : "Selecteer" } alle artikelen</span>}
                              <Divider />
                              <Grid container>
                                {this.state.rijenRISExport.map((rijRISExport) => {                                
                                  return(
                                    <React.Fragment>                                 
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <MuiThemeProvider theme={window.purpleTheme}>
                                          <IconButton
                                            onClick={(event) => this.handleOnclickSelect(rijRISExport.artikelId)}
                                            color="primary"
                                          >
                                            {rijRISExport.geselecteerd ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                                          </IconButton>
                                        </MuiThemeProvider>
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <Box paddingTop={1.5}>{rijRISExport.rijnummer}</Box>
                                      </Grid>                                  
                                      <Grid item xs={10} sm={10} md={10} lg={10} justify="bottom" direction="column">
                                        <Box paddingTop={1.5}>
                                          {rijRISExport.artikelAuteurs}, '{rijRISExport.artikelTitel}' <i>{rijRISExport.artikelTijdschrift}</i> {rijRISExport.artikelTijdschriftInfo}
                                        </Box>
                                      </Grid>                                  
                                    </React.Fragment>                                 
                                  );
                                })}
                              </Grid>                                  
                              <Divider />
                              <div align= "right">
                                <MuiThemeProvider theme={window.purpleTheme}>
                                  <Tooltip title="RIS export" placement="top" arrow>
                                      <IconButton
                                        onClick={this.handleExport.bind(this)}
                                        color="primary"
                                        style={{ paddingLeft: 0, paddingRight: 15, paddingTop: 10, paddingBottom: 10, float: "right"}}
                                      >
                                        <ExitToAppIcon />
                                      </IconButton>
                                  </Tooltip>
                                </MuiThemeProvider>
                                <MuiThemeProvider theme={window.purpleTheme}>
                                  <Tooltip title="Annuleren" placement="top" arrow>
                                      <IconButton
                                        onClick={this.handleOnclickCancelRISExport.bind(this)}
                                        color="primary"
                                        style={{ paddingLeft: 0, paddingRight: 10, paddingTop: 10, paddingBottom: 10, float: "right"}}
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                  </Tooltip>
                                </MuiThemeProvider>
                              </div>
                            </div>                          
                          </Popover>
                        </span> 
                        : ""
                      }
                    </td>
                    <td align="right">
                    </td>
                  </tr>
                </tbody>
              </table>        
            </div>
            : 
            <div style={{marginTop : 40}}>
              <table style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <td align="left">
                      <MuiThemeProvider theme={window.tekstGrootTheme}>
                        <Typography variant="h1" display="inline" color="textPrimary">Uw zoekactie heeft geen artikelen opgeleverd</Typography>
                      </MuiThemeProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            }
          </React.Fragment>
      )
    } else {
      return (<CircularProgress/>);
    }
  }
}
export default withRouter(ZoekResultaten);