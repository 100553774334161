import React from 'react';
import ZoekResultaten from './ZoekResultaten';
import Zoekveldrij from './Zoekveldrij';
import Foutmelding from './Foutmelding';
import {Checkbox, Button, TextField, IconButton, Popover} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ClearIcon from '@material-ui/icons/Clear';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { Switch, Route, withRouter } from 'react-router-dom';
import rijenParamNaarZoekvelden from './functies';
import CancelIcon from "@material-ui/icons/Cancel";

class Zoekformulier extends React.Component {

  constructor(props) {
    super(props);

    this.clientSleutel="";
    this.lijstOperatoren = ['En','Of','En niet'];
    this.lijstTypen = ['Titel','Auteur','Tijdschrift','Jaar','Sleutelwoord','Categorie','Bijbelboek','Bijbellocatie'];
    this.hoogsteZoekveldrijId = this.props.match.params.rijen ? this.props.match.params.rijen.length : 1;

    this.laatsteEenvoudigZoekenLocation = this.props.location.pathname.indexOf('/eenvoudig') !== -1 ? this.props.location.pathname : '/eenvoudig';
    this.laatsteGeavanceerdZoekenLocation = this.props.location.pathname.indexOf('/geavanceerd') !== -1 ? this.props.location.pathname : '/geavanceerd';
    this.retryUpdateTitel=0;
    this.state = {
      error: false,
      errorMessage: "",
      eenvoudigZoeken: true,
      zoekveldEenvoudigZoeken: this.props.match.params.termen ? this.props.match.params.termen.replaceAll("|"," ") : "",
      resultatenGezocht: false,
      totaalAantalArtikelen : 0,
      zoekveldrijen : this.props.match.params.rijen ? rijenParamNaarZoekvelden(this.props.match.params.rijen) : [
      {
        veldrijId: 1,
        veldOperator : '',
        veldType : this.lijstTypen[0],
        veldRelatie : '',
        veldWaarde: ''
      }],
      lijstTijdschriften : [],
      lijstSleutelwoorden : [],
      lijstBijbelboeken : [],
      lijstCategorieen : [],
      jaren : [],
      bericht : '',
      isLoadingZoekresultaten: false,
      popoverGeldigeInvoerOpen: false,
      anchorElGeldigeInvoer: null,
      popoverGeldigeInvoerTekst: ""
    };
  }

  zoekveldrijenNaarTitel() {

    var titel = "";

    // zijn de lijsten al geladen ?
    if (this.state.lijstCategorieen.length > 0 && this.state.lijstTijdschriften.length > 0 &&
      this.state.lijstBijbelboeken.length > 0 && this.state.lijstSleutelwoorden.length > 0) {

      // reset retry teller
      this.retryUpdateTitel=0;

      // stel titel samen
      this.state.zoekveldrijen.forEach((veldrij) => {
    
        if (veldrij.veldOperator !== 'En niet') {
          switch (veldrij.veldType) {
            case 'Auteur':
              if (titel !== "") {titel += ", ";}
              titel += veldrij.veldWaarde.replaceAll('+', ', ');
              break;
            case 'Titel':
              if (titel !== "") {titel += ", ";}
              titel += veldrij.veldWaarde.replaceAll('+', ', ');
              break;
            case 'Categorie':
              this.state.lijstCategorieen.forEach((categorie) => {
                if (categorie.categorieId===veldrij.veldWaarde) {
                  if (titel !== "") {titel += ", ";}
                  titel += categorie.categorie;
                }
              });
              break;
            case 'Tijdschrift':
              this.state.lijstTijdschriften.forEach((tijdschrift) => {
                if (tijdschrift.tijdschriftId===veldrij.veldWaarde) {
                  if (titel !== "") {titel += ", ";}
                  titel += tijdschrift.naam;
                }
              });
              break;
            case 'Bijbellocatie':
              var bijbelboekId= veldrij.veldWaarde.bijbelboekId;
              var hoofdstukBegin= veldrij.veldWaarde.hoofdstukBegin;
              var versBegin= veldrij.veldWaarde.versBegin;
              var hoofdstukEinde= veldrij.veldWaarde.hoofdstukEinde;
              var versEinde= veldrij.veldWaarde.versEinde;
              this.state.lijstBijbelboeken.forEach((bijbelboek) => {
                if (bijbelboek.bijbelboekId===bijbelboekId) {
                  if (titel !== "") {titel += ", ";}
                  titel += bijbelboek.naam+' '+hoofdstukBegin+':'+versBegin+'-';
                  if (hoofdstukBegin<hoofdstukEinde) {titel += hoofdstukEinde+':';}
                  titel += versEinde;
                }
              });
              break;
            case 'Bijbelboek':
              this.state.lijstBijbelboeken.forEach((bijbelboek) => {
                if (bijbelboek.bijbelboekId===veldrij.veldWaarde) {
                  if (titel !== "") {titel += ", ";}
                  titel += bijbelboek.naam;
                }
              });
              break;
            case 'Sleutelwoord':
              this.state.lijstSleutelwoorden.forEach((sleutelwoord) => {
                if (sleutelwoord.sleutelwoordId===veldrij.veldWaarde) {
                  if (titel !== "") {titel += ", ";}
                  titel += sleutelwoord.sleutelwoord;
                }
              });
              break;
            case 'Jaar':
              if (titel !== "") {titel += ", ";}
              titel += veldrij.veldWaarde;
              break;
            default:
              break;
          }
        }
      });
    } else {
      // lijsten zijn nog niet geladen: asynchrone timeout
      if (this.retryUpdateTitel < 20) {
        this.retryUpdateTitel++;
        setTimeout(this.zoekveldrijenNaarTitel.bind(this),50);
      } else {
        this.retryUpdateTitel=0;
      }
    }
    if (titel === "") {titel = "Theologische tijdschriften";}
    document.title = titel;
  };

  componentDidUpdate(prevProps) {

    if(this.props.location.pathname.indexOf('/eenvoudig') !== -1) {
      this.laatsteEenvoudigZoekenLocation = this.props.location.pathname;

      if(!this.state.eenvoudigZoeken) {
        this.setState({eenvoudigZoeken: true});
      }
    }

    if(this.props.location.pathname.indexOf('/geavanceerd') !== -1) {
      this.laatsteGeavanceerdZoekenLocation = this.props.location.pathname;

      if(this.state.eenvoudigZoeken) {
        this.setState({eenvoudigZoeken: false});
      }
    }

    if(this.props.match.params.termen && this.props.match.params.termen !== prevProps.match.params.termen) {
      this.setState({zoekveldEenvoudigZoeken: this.props.match.params.termen.replaceAll("|", " ")});
    }
    if(this.props.match.params.rijen && this.props.match.params.rijen !== prevProps.match.params.rijen) {
      this.setState({zoekveldrijen: rijenParamNaarZoekvelden(this.props.match.params.rijen)});
    }
  }

  componentDidMount() {

    var serviceNummer;
    var randomGetal;
    var controleGetal;
    var controleCode;

    //Bepaal clientsleutel
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      this.clientSleutel = "qfOORX9YyFxkGA5SbK9MiCP9Vczdu0z8";
    } else {
      this.clientSleutel = window.client_sleutel;
    }

    //Bepaal controlecode voor readAantalArtikelen
    serviceNummer = 8165;
    randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    controleGetal = serviceNummer * randomGetal;
    controleCode = randomGetal.toString() + controleGetal.toString();

    //Lees totaalAantalArtikelen uit database
    this.setState({
      error: false,
      errorMessage: ""
    });

    fetch("/api/artikel/readAantalArtikelen.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel)
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.aantalArtikelen != "undefined") {
          this.setState({totaalAantalArtikelen: result.aantalArtikelen});
        }
        else if (typeof result.message != "undefined"){
          if (result.message.substr(0, 4)=== "Fout") {
            this.setState({
              error: true,
              errorMessage: "Er gaat iets mis bij het ophalen van het aantal artikelen."
            });        
          }
          else {
            this.setState({
              error: true,
              errorMessage: result.message
            });        
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van het aantal artikelen."
          });        
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het ophalen van het aantal artikelen."
        });
      }
    );
    
    //Lees info tijdschriften uit database
    this.setState({
      error: false,
      errorMessage: ""
    });

    //Bepaal controlecode voor tijdschrift/readAll
    serviceNummer = 9645;
    randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    controleGetal = serviceNummer * randomGetal;
    controleCode = randomGetal.toString() + controleGetal.toString();
        
    fetch("/api/tijdschrift/readAll.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel)
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.records != "undefined") {
          this.setState({lijstTijdschriften: result.records});
        }
        else if (typeof result.message != "undefined"){
          if (result.message.substr(0, 4)=== "Fout") {
            this.setState({
              error: true,
              errorMessage: "Er gaat iets mis bij het ophalen van tijdschriften."
            });        
          }
          else {
            this.setState({
              error: true,
              errorMessage: result.message
            });        
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van tijdschriften."
          });        
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het ophalen van tijdschriften."
        });
      }
    );

    //Lees sleutelwoorden uit database
    this.setState({
      error: false,
      errorMessage: ""
    });

    //Bepaal controlecode voor sleutelwoord/readGekoppeld
    serviceNummer = 7832;
    randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    controleGetal = serviceNummer * randomGetal;
    controleCode = randomGetal.toString() + controleGetal.toString();

    fetch("/api/sleutelwoord/readGekoppeld.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel)
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.records != "undefined") {
          this.setState({lijstSleutelwoorden: result.records});
        }
        else if (typeof result.message != "undefined"){
          if (result.message.substr(0, 4)=== "Fout") {
            this.setState({
              error: true,
              errorMessage: "Er gaat iets mis bij het ophalen van sleutelwoorden."
            });        
          }
          else {
            this.setState({
              error: true,
              errorMessage: result.message
            });        
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van sleutelwoorden."
          });        
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het ophalen van sleutelwoorden."
        });
      }
    );

    //Lees info categorieen uit database
    this.setState({
      error: false,
      errorMessage: ""
    });

    //Bepaal controlecode voor categorie/readAll
    serviceNummer = 6974;
    randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    controleGetal = serviceNummer * randomGetal;
    controleCode = randomGetal.toString() + controleGetal.toString();

    fetch("/api/categorie/readAll.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel)
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.records != "undefined") {
          this.setState({lijstCategorieen: result.records});
        }
        else if (typeof result.message != "undefined"){
          if (result.message.substr(0, 4)=== "Fout") {
            this.setState({
              error: true,
              errorMessage: "Er gaat iets mis bij het ophalen van categorieën."
            });        
          }
          else {
            this.setState({
              error: true,
              errorMessage: result.message
            });        
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van categorieën."
          });        
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het ophalen van categorieën."
        });
      }
    );

    //Lees info bijbelboeken uit database
    this.setState({
      error: false,
      errorMessage: ""
    });

    //Bepaal controlecode voor bijbelboek/readAll
    serviceNummer = 5856;
    randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    controleGetal = serviceNummer * randomGetal;
    controleCode = randomGetal.toString() + controleGetal.toString();

    fetch("/api/bijbelboek/readAll.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel)
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.records != "undefined") {
          this.setState({lijstBijbelboeken: result.records});
        }
        else if (typeof result.message != "undefined"){
          if (result.message.substr(0, 4)=== "Fout") {
            this.setState({
              error: true,
              errorMessage: "Er gaat iets mis bij het ophalen van bijbelboeken."
            });        
          }
          else {
            this.setState({
              error: true,
              errorMessage: result.message
            });        
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van bijbelboeken."
          });        
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het ophalen van bijbelboeken."
        });
      }
    );

    //Lees vroegste en laatste jaar uit database
    this.setState({
      error: false,
      errorMessage: ""
    });

    //Bepaal controlecode voor artikel/readMinMaxJaar
    serviceNummer = 4823;
    randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    controleGetal = serviceNummer * randomGetal;
    controleCode = randomGetal.toString() + controleGetal.toString();

    fetch("/api/artikel/readMinMaxJaar.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel)
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.jaren != "undefined") {
          this.setState({jaren: result.jaren});
        }
        else if (typeof result.message != "undefined"){
          if (result.message.substr(0, 4)=== "Fout") {
            this.setState({
              error: true,
              errorMessage: "Er gaat iets mis bij het ophalen van jaartallen."
            });        
          }
          else {
            this.setState({
              error: true,
              errorMessage: result.message
            });        
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van jaartallen."
          });        
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het ophalen van jaartallen."
        });
      }
    );

    //Lees info active berichten uit database. Vooralsnog wordt ervan uitgegaan dat er maar 1 bericht actief is (record[0])
    this.setState({
      error: false,
      errorMessage: ""
    });

    //Bepaal controlecode voor bericht/readActive
    serviceNummer = 2694;
    randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    controleGetal = serviceNummer * randomGetal;
    controleCode = randomGetal.toString() + controleGetal.toString();
        
    fetch("/api/bericht/readActive.php?d="+Date.now()+"&c="+controleCode+"&s="+this.clientSleutel)
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.records != "undefined") {
          this.setState({bericht: result.records[0].tekst});
        }
        else if (typeof result.message != "undefined"){
          if (result.message.substr(0, 4)=== "Fout") {
            this.setState({
              error: true,
              errorMessage: "Er gaat iets mis bij het ophalen van berichten."
            });        
          }
          else {
            this.setState({bericht: ''});
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het ophalen van berichten."
          });        
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het ophalen van berichten."
        });
      }
    );
  }

  afhandelenZoekveldrijen (vervolgZoekactie, onlineArtikelen, geenRecensies) {
    //Functie handelt Zoekveldrijen af

    //Verwijder zoekveldrijen waarvan het veld Waarde leeg is
    var zoekveldrijen = this.state.zoekveldrijen.filter((zoekveldrij) => {
      if (zoekveldrij.veldWaarde !== '' && zoekveldrij.veldWaarde !== null) {
          return zoekveldrij;
      }
      else {
        return null;
      }          
    });

    if (zoekveldrijen.length > 0) {

      var url = '/geavanceerd/zoeken/';

      var eersteZoekveldrij = true;

      zoekveldrijen.forEach((zoekveldrij) => {

        if(!eersteZoekveldrij) {
          url += "|";
        }
        eersteZoekveldrij = false;

        switch (zoekveldrij.veldOperator) {
          case 'En':
            url += 'E-';
            break;
          case 'Of':
            url += 'O-';
            break;
          case 'En niet':
            url += 'N-';
            break;
          default:
            url += 'G-';
            break;
        }

        switch (zoekveldrij.veldType) {

          case 'Auteur':
            url += 'at';
            break;
          case 'Titel':
            url += 'ti';
            break;
          case 'Categorie':
            url += 'ca';
            break;
          case 'Tijdschrift':
            url += 'ts';
            break;
          case 'Bijbellocatie':
            url += 'bl';
            break;
          case 'Bijbelboek':
            url += 'bb';
            break;
          case 'Sleutelwoord':
            url += 'sw';
            break;
          case 'Jaar':
            url += 'jr';
            break;
          default:
            break;
        }

        url += '-'+zoekveldrij.veldRelatie;

        if(zoekveldrij.veldType === 'Bijbellocatie') {
          url += '-'+zoekveldrij.veldWaarde.bijbelboekId+";"+zoekveldrij.veldWaarde.hoofdstukBegin+";"+zoekveldrij.veldWaarde.versBegin+";"+zoekveldrij.veldWaarde.hoofdstukEinde+";"+zoekveldrij.veldWaarde.versEinde;
        } else {
          url += '-'+new String(zoekveldrij.veldWaarde).replaceAll(' ', '+');
        }
      });

      url += "/"+(onlineArtikelen || 0);

      url += "/"+(geenRecensies || 0);

      url += "/"+(vervolgZoekactie ? 1 : 0);

      if(this.props.match.params.sorteeroptie) {
        url += '/'+this.props.match.params.sorteeroptie+'/'+this.props.match.params.aantalperpagina+'/1';
      }
      if (this.props.history.location.pathname !== url) {
        this.props.history.push(url);
      }
      //document.title = this.rijenParamNaarTitel();
    }
    else {
      this.props.history.push('/geavanceerd');
    }
  }

  afhandelenZoektermen(vervolgZoekactie, onlineArtikelen, geenRecensies) {
    //Functie handelt zoektermen Eenvoudig zoeken af
    var zoektermen = [];
    if (!vervolgZoekactie) {
      //Converteer zoekveld naar zoektermen
      //Filter zoektermen die bestaan uit cijfers
      //Filter zoektermen van 1 karakter lang
      var zoekveld = this.state.zoekveldEenvoudigZoeken;
      var startPositie=0;
      var indexPositie;
      var indexKarakter;
      var zoekterm;
      if (zoekveld.substr(0, 1)==="'") {
        indexKarakter="'";
        startPositie=1;
      } else {
        indexKarakter=" ";
        startPositie=0;
      }
      do {
        zoekterm="";
        indexPositie = zoekveld.indexOf(indexKarakter,startPositie);
        if (indexPositie > -1) {
          //bepaal zoekterm
          if (indexKarakter==="'") {
            zoekterm=zoekveld.substring(startPositie-1, indexPositie+1);
          } else {
            zoekterm=zoekveld.substring(startPositie, indexPositie);
          }
          //Bepaal indexKarakter en startPositievoor vervolg
          if (zoekveld.substr(indexPositie+1, 1)===" ") {
            if (zoekveld.substr(indexPositie+2, 1)==="'") {
              indexKarakter="'";
              startPositie=indexPositie+3;
            } else {
              indexKarakter=" ";
              startPositie=indexPositie+2;
            }
            startPositie=indexPositie+2;
          } else {
            if (zoekveld.substr(indexPositie+1, 1)==="'") {
              indexKarakter="'";
              startPositie=indexPositie+2;
            } else {
              indexKarakter=" ";
              startPositie=indexPositie+1;
            }
          }
        } else {
          //Bepaal zoekterm
          zoekterm=zoekveld.substring(startPositie, zoekveld.length);
        }
        //
        if (zoekterm!=="") {
          zoektermen.push(zoekterm);
        }
      } while (indexPositie > -1);
    } else {
      zoektermen=this.state.zoektermenResultaat;
    }
    var url='/eenvoudig/zoeken/' + zoektermen.join("|") +"/"+(onlineArtikelen || 0) +"/"+(geenRecensies || 0)+"/"+(vervolgZoekactie ? 1 : 0)+(this.props.match.params.sorteeroptie ? '/'+this.props.match.params.sorteeroptie+'/'+this.props.match.params.aantalperpagina+'/1' : '');
    if (zoektermen.length>0) {
      if (this.props.history.location.pathname !== url) {
        this.props.history.push(url);
      }
      //document.title=this.state.zoekveldEenvoudigZoeken;
    }
    else {
      this.props.history.push('/eenvoudig');
    }
  }

  handleEnterKey(evt) {
    //Functie handelt ZoekButton af
    if (this.state.eenvoudigZoeken) {
      this.afhandelenZoektermen(false, this.props.match.params.onlineartikelen, this.props.match.params.geenrecensies)
    } else {
      this.afhandelenZoekveldrijen (false, this.props.match.params.onlineartikelen, this.props.match.params.geenrecensies);
    }
    this.setState({resultatenGezocht: true});
  }

  handleZoekButton(evt) {
    //Functie handelt ZoekButton af
    if (this.state.eenvoudigZoeken) {
      this.afhandelenZoektermen(false, this.props.match.params.onlineartikelen, this.props.match.params.geenrecensies)
    } else {
      this.afhandelenZoekveldrijen (false, this.props.match.params.onlineartikelen, this.props.match.params.geenrecensies);
    }
    this.setState({resultatenGezocht: true});
  }

  handleAddEvent(evt) {
    //Functie handelt toevoegen van zoekveldrij af
    var id = ++this.hoogsteZoekveldrijId;
    this.hoogsteZoekveldrijId = id;
    var zoekveldrij = {
      veldrijId: id,
      veldOperator : this.lijstOperatoren[0],
      veldType : this.lijstTypen[0],
      veldRelatie : '',
      veldWaarde: ''
    }
    var newZoekveldrijen = this.state.zoekveldrijen.slice();
    newZoekveldrijen.push(zoekveldrij);
    this.setState({zoekveldrijen: newZoekveldrijen});
  } 

  handleRowDel(veldNaam) {     
    //Functie handelt verwijderen van zoekveldrij af    
    // velden hebben naam deleteButton-<veldrijId>
    var veldrijnummer = veldNaam.substring(13, veldNaam.length);
    var index;
    //Verwijder zoekrij
    for (index = 0; index < this.state.zoekveldrijen.length; ++index) {
      var veldrij = this.state.zoekveldrijen[index];
      if (Number(veldrij.veldrijId)===Number(veldrijnummer)) {
        var newZoekveldrijen = this.state.zoekveldrijen.slice();
        newZoekveldrijen.splice(index, 1);
      }
    }
    //Wis veldOperator van eerste veldrij
    newZoekveldrijen[0].veldOperator='';
    this.setState({zoekveldrijen: newZoekveldrijen});
  }

  handleChangeOperator(evt) {

    //Functie handelt wijziging in veldOperator af
    var item = {
      name: evt.target.name,
      value: evt.target.value
    };
    var zoekveldrijen = this.state.zoekveldrijen.slice();
    //Het veldrijnummer begint op positie 13 : veldOperator-<veldrijId>
    var veldrij = item.name.substring(13, item.name.length);
    var newZoekveldrijen = zoekveldrijen.map(function(zoekveldrij) {
      if (Number(zoekveldrij.veldrijId) === Number(veldrij)) {
        zoekveldrij.veldOperator = item.value;
      }      
      return zoekveldrij;      
    });
    
    this.setState({zoekveldrijen: newZoekveldrijen});
  
  }

  handleChangeType(evt) {
    //Functie handelt wijziging in veldType af
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value
    };
    var zoekveldrijen = this.state.zoekveldrijen.slice();
    //Het veldrijnummer begint op positie 9 : veldType-<veldrijId>
    var veldrij = item.name.substring(9, item.name.length);
    var eersteTijdschrift = this.state.lijstTijdschriften[0];
    var tijdschriftId = eersteTijdschrift.tijdschriftId;
    var minJaar=this.state.jaren.minJaar<1950 ? 1950 : this.state.jaren.minJaar;
    var newZoekveldrijen = zoekveldrijen.map(function(zoekveldrij) {

      if (Number(zoekveldrij.veldrijId) === Number(veldrij)) {
        zoekveldrij.veldType = item.value;
        if (zoekveldrij.veldType === "Tijdschrift") {
          zoekveldrij.veldWaarde = tijdschriftId;
        }
        else if (zoekveldrij.veldType === "Bijbellocatie") {
          zoekveldrij.veldWaarde = null;
        }
        else if (zoekveldrij.veldType === "Bijbelboek") {
          zoekveldrij.veldWaarde = null;
        }
        else if (zoekveldrij.veldType === "Sleutelwoord") {
          zoekveldrij.veldWaarde = null;
        }
        else if (zoekveldrij.veldType === "Jaar") {
          zoekveldrij.veldRelatie = 1;
          zoekveldrij.veldWaarde = minJaar;
        }
        else {
          zoekveldrij.veldWaarde = '';
        }          
      }
      return zoekveldrij;      
    });    
    this.setState({zoekveldrijen: newZoekveldrijen});  
  }

  handleChangeRelatie(evt) {

    //Functie handelt wijziging in veldRelatie af
    var item = {
      name: evt.target.name,
      value: evt.target.value
    };
    var zoekveldrijen = this.state.zoekveldrijen.slice();
    //Het veldrijnummer begint op positie 12 : veldRelatie-<veldrijId>
    var veldrij = item.name.substring(12, item.name.length);
    var huidigeJaar = new Date().getFullYear();
    var minJaar=this.state.jaren.minJaar<1950 ? 1950 : this.state.jaren.minJaar;
    var maxJaar=this.state.jaren.maxJaar>huidigeJaar ? huidigeJaar : this.state.jaren.maxJaar;
     var newZoekveldrijen = zoekveldrijen.map(function(zoekveldrij) {
      if (Number(zoekveldrij.veldrijId) === Number(veldrij)) {
        zoekveldrij.veldRelatie = item.value;
        if (Number(zoekveldrij.veldRelatie)===1) {
          zoekveldrij.veldWaarde = minJaar;
        }
        else {
          zoekveldrij.veldWaarde = maxJaar;
        }
      }      
      return zoekveldrij;      
    });    
    this.setState({zoekveldrijen: newZoekveldrijen});  
  }

  handleChangeWaarde(veldNaam, veldType, veldWaarde, currentTarget) {
    //Functie handelt wijziging in veldWaarden af

    var geldigeInvoer=true;
    var geldigeInvoerTekst;
    var karakter;
    var waarde;

    // velden hebben naam veldWaarde-<veldrijId>
    var veldrij = veldNaam.substring(11, veldNaam.length);
    var zoekveldrijen = this.state.zoekveldrijen.slice();
    var newZoekveldrijen = zoekveldrijen.map(function(zoekveldrij) {

      if (Number(zoekveldrij.veldrijId) === Number(veldrij)) {
        if(veldType === 'Sleutelwoord') {
          if (veldWaarde !== null) {
            zoekveldrij.veldWaarde = veldWaarde.sleutelwoordId;
          } else {
            zoekveldrij.veldWaarde = null;
          }
        } 
        else if(veldType === 'Bijbelboek') {
          if (veldWaarde !== null) {
            zoekveldrij.veldWaarde = veldWaarde.bijbelboekId;
          } else {
            zoekveldrij.veldWaarde = null;
          }
        }
        else if(veldType === 'Auteur') {
          //Check geldige invoer: alfabetisch spatie ampersand asterix
          for (let i=0;i < veldWaarde.length; i++) {
            karakter = veldWaarde.substr(i,1);
            if (!(/[A-Za-z\s]/.test(karakter) || karakter==="&" || karakter==="*")) {
              geldigeInvoer=false;
            }
          }
          if (geldigeInvoer) {
            zoekveldrij.veldWaarde = veldWaarde;
          } else {
            geldigeInvoerTekst="Dit veld accepteert alleen: letters zonder accenten, cijfers, spaties, ampersand (&) en asterix (*).";
          }
        }
        else if(veldType === 'Titel') {
          //Vervang dubbele aanhalingstekens door apostrof
          waarde=veldWaarde.replaceAll('"',"'");
          //Check geldige invoer: alfanumeriek, spatie, ampersand, asterix, apostrof
          for (let i=0;i < waarde.length; i++) {
            karakter = waarde.substr(i,1);
            if (!(/[A-Za-z0-9\s]/.test(karakter) || karakter==="&" || karakter==="*" || karakter==="'" )) {
              geldigeInvoer=false;
            }
          }
          if (geldigeInvoer) {
            zoekveldrij.veldWaarde = waarde;
          } else {
            geldigeInvoerTekst="Dit veld accepteert alleen: letters zonder accenten, cijfers, spaties, ampersand (&), asterix (*) en apostrof (').";
          }
        }
        else {          
          zoekveldrij.veldWaarde = veldWaarde;
        }
      }      
      return zoekveldrij;
    });

    this.setState({zoekveldrijen: newZoekveldrijen});

    if (!geldigeInvoer) {
      this.setState({
        popoverGeldigeInvoerOpen: true,
        anchorElGeldigeInvoer: currentTarget,
        popoverGeldigeInvoerTekst: geldigeInvoerTekst
      });
    }
  }

  handleChangeOnlineArtikelen (event) {
    var onlineArtikelen = event.target.checked ? 1 : 0;

    if (this.state.eenvoudigZoeken) {
      this.afhandelenZoektermen(false, onlineArtikelen, this.props.match.params.geenrecensies)
    } else {
      this.afhandelenZoekveldrijen (false, onlineArtikelen, this.props.match.params.geenrecensies);
    }
    this.setState({resultatenGezocht: true});
  }

  handleChangeGeenRecensies (event) {
    var geenRecensies = event.target.checked ? 1 : 0;

    if (this.state.eenvoudigZoeken) {
      this.afhandelenZoektermen(false, this.props.match.params.onlineartikelen, geenRecensies)
    } else {
      this.afhandelenZoekveldrijen (false, this.props.match.params.onlineartikelen, geenRecensies);
    }
    this.setState({resultatenGezocht: true});

  }

  handleToggleZoekmode() {

    var newUrl = '';
    document.title="Theologische tijdschriften";
    if(this.state.eenvoudigZoeken) {
      newUrl = this.laatsteGeavanceerdZoekenLocation;
    } else {
      newUrl = this.laatsteEenvoudigZoekenLocation;
    }

    this.props.history.push(newUrl);
  }

  handleChanceZoekveldEenvoudigZoeken(event) {
    //Vervang dubbele aanhalingstekens door apostrof
    var zoekterm=event.target.value.replaceAll('"',"'");
    //Check geldige invoer: alfanumeriek, spatie, ampersand, asterix, apostrof
    var geldigeInvoer=true;
    var karakter;
    for (let i=0;i < zoekterm.length; i++) {
      karakter = zoekterm.substr(i,1);
      if (!(/[A-Za-z0-9\s]/.test(karakter) || karakter==="&" || karakter==="*" || karakter==="'" )) {
        geldigeInvoer=false;
      }
    }
    if (geldigeInvoer) {
      this.setState({
        zoekveldEenvoudigZoeken: zoekterm
      })
    } else {
      this.setState({
        popoverGeldigeInvoerOpen: true,
        anchorElGeldigeInvoer: event.currentTarget,
        popoverGeldigeInvoerTekst: "Dit veld accepteert alleen: letters zonder accenten, cijfers, spaties, ampersand (&), asterix (*) en apostrof (')."
      }); 
    }
  }

  handleClearZoekveldEenvoudigZoeken() {
    this.setState({
      zoekveldEenvoudigZoeken: ""
    })
  }

  handleOnclickCancel() {
    this.setState({
      popoverGeldigeInvoerOpen: false,
      anchorElGeldigeInvoer: null
    });
  }

  render() {

    return (
      <div>
        {this.state.error ? 
          <Foutmelding
            foutmelding={this.state.errorMessage}
          /> 
          : ""
        }
        <div>
          <table style={{width: "100%"}}>
            <tbody>
              <tr>
                <td align="left">
                  <MuiThemeProvider theme={window.tekstGrootTheme}>
                    <Typography variant="h1" display="inline" color="textPrimary" style={{ fontWeight: 500 }}>{this.state.eenvoudigZoeken ? "Eenvoudig" : "Geavanceerd"}</Typography>
                    <Typography variant="h1" display="inline" color="textPrimary"> zoeken in </Typography>
                    <Typography variant="h1" display="inline" color="textSecondary">{this.state.totaalAantalArtikelen}</Typography>
                    <Typography variant="h1" display="inline" color="textPrimary"> artikelen in </Typography>
                    <Typography variant="h1" display="inline" color="textSecondary">{this.state.lijstTijdschriften.length}</Typography>
                    <Typography variant="h1" display="inline" color="textPrimary"> tijdschriften:</Typography>
                  </MuiThemeProvider>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table style={{marginTop : 30, width: "100%"}}>
          <tbody>
            <Switch>
              <Route path="/geavanceerd">{this.state.zoekveldrijen.map((zoekveldrij, index) => { 
                  return (<Zoekveldrij
                    key = {zoekveldrij.veldrijId}
                    zoekveldrij={zoekveldrij}
                    eersteVeldrij={Number(index)===0}
                    laatsteVeldrij={Number(this.state.zoekveldrijen.length)===Number(index+1)} 
                    aantalVeldrijen={this.state.zoekveldrijen.length} 
                    lijstOperatoren={this.lijstOperatoren} 
                    lijstTypen={this.lijstTypen} 
                    lijstTijdschriften={this.state.lijstTijdschriften.slice()}
                    lijstSleutelwoorden={this.state.lijstSleutelwoorden.slice()}
                    lijstCategorieen={this.state.lijstCategorieen.slice()}
                    lijstBijbelboeken={this.state.lijstBijbelboeken.slice()}
                    jaren={this.state.jaren}
                    onChangeOperator={this.handleChangeOperator.bind(this)}
                    onChangeType={this.handleChangeType.bind(this)} 
                    onChangeWaarde={this.handleChangeWaarde.bind(this)} 
                    onChangeRelatie={this.handleChangeRelatie.bind(this)} 
                    onEnterKey={this.handleEnterKey.bind(this)}
                    onRowDel={this.handleRowDel.bind(this)}
                    onRowAdd={this.handleAddEvent.bind(this)}
                  />);
                })}
              </Route>
              <Route path={["/","/eenvoudig"]}>
                <tr>
                  <td style={{width:"95%"}}>
                    <TextField
                      type='text' 
                      name="zoekveldEenvoudigZoeken" 
                      onChange={this.handleChanceZoekveldEenvoudigZoeken.bind(this)} 
                      onKeyDown={(e) => {
                        if (e.key === 13 || e.key === 'Enter') {
                          this.handleEnterKey();
                        }
                      }}
                      value={this.state.zoekveldEenvoudigZoeken}
                      placeholder="Voer hier uw zoektermen in"
                      style={{width:"100%"}}
                    />
                  </td>
                  <td style={{width:"5%"}}>
                    <IconButton
                      onClick={this.handleClearZoekveldEenvoudigZoeken.bind(this)}
                      style={{ paddingTop: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </td>
                </tr>
              </Route>
            </Switch>
            <Popover
              open={this.state.popoverGeldigeInvoerOpen}
              anchorEl={this.state.anchorElGeldigeInvoer}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              <div style={{ width: 370, padding: 15 }}>
                {<span><b>{this.state.popoverGeldigeInvoerTekst}</b></span>}
              </div>
              <MuiThemeProvider theme={window.purpleTheme}>
                <IconButton
                  onClick={this.handleOnclickCancel.bind(this)}
                  style={{ marginLeft: 0 }}
                  color="primary"
                >
                  <CancelIcon />
                </IconButton>
              </MuiThemeProvider>
            </Popover>                    
          </tbody>  
        </table>
        <div style={{marginTop : 30}}/>
        <MuiThemeProvider theme={window.purpleTheme}>
          <Checkbox
            padding={0}
            id="onlineArtikelen"
            checked={Number(this.props.match.params.onlineartikelen)===1}
            onChange={this.handleChangeOnlineArtikelen.bind(this)}
            color="primary"
            />			
        </MuiThemeProvider>
        <span>
          alleen online beschikbare artikelen tonen
        </span>
        <div style={{marginTop : 0}}/>
        <MuiThemeProvider theme={window.purpleTheme}>
          <Checkbox
            padding={0}
            id="geenRecensies"
            checked={Number(this.props.match.params.geenrecensies)===1}
            onChange={this.handleChangeGeenRecensies.bind(this)}
            color="primary"
            />			
        </MuiThemeProvider>
        <span>
          geen recensies en columns tonen
        </span>
        <div style={{marginTop : 20}}/>
        <MuiThemeProvider theme={window.purpleTheme}>
          <Button 
            variant="contained" 
            size="small"
            color="primary"
            onClick={this.handleZoekButton.bind(this)} 
            style={{textTransform: 'none', marginLeft:10, borderRadius: 0}}
            endIcon={
              <MuiThemeProvider theme={window.orangeTheme}>
                <PlayArrowIcon 
                  color="primary"
                />
              </MuiThemeProvider>
            }
            >              
              <MuiThemeProvider theme={window.tekstGrootTheme2}>
                <Typography variant="h1" >Zoeken</Typography>
              </MuiThemeProvider>
          </Button>
          <div/>

          <Button
            variant="contained"
            id="zoekmode"
            color="primary"
            onClick={this.handleToggleZoekmode.bind(this)}
            style={{textTransform: 'none', marginTop:15, marginLeft:10, borderRadius: 0}}
            endIcon={
              <MuiThemeProvider theme={window.orangeTheme}>
                <PlayArrowIcon 
                  color="primary"
                />
              </MuiThemeProvider>
            }
          >
            <MuiThemeProvider theme={window.selectieButtonTheme}>
              <Typography variant="body1">Ga naar {this.state.eenvoudigZoeken ? <span>Geavanceerd</span> : <span>Eenvoudig</span>} zoeken</Typography>
            </MuiThemeProvider>
          </Button> 
        </MuiThemeProvider>
        <Switch>
          <Route path={[
              "/eenvoudig/zoeken/:termen/:onlineartikelen/:geenrecensies/:vervolgzoekactie/:sorteeroptie/:aantalperpagina/:pagina",
              "/eenvoudig/zoeken/:termen/:onlineartikelen/:geenrecensies",
              "/geavanceerd/zoeken/:rijen/seo",
              "/geavanceerd/zoeken/:rijen/tijdschrift",
              "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies/:vervolgzoekactie/seo",
              "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies/:vervolgzoekactie/:sorteeroptie/:aantalperpagina/:pagina/seo",
              "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies/:vervolgzoekactie/:sorteeroptie/:aantalperpagina/:pagina/tijdschrift",
              "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies/:vervolgzoekactie/:sorteeroptie/:aantalperpagina/:pagina",
              "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies/:vervolgzoekactie/tijdschrift",
              "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies/seo",
              "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies/tijdschrift",
              "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies"
              ]}>
            <ZoekResultaten
              zoekveldrijenNaarTitel={this.zoekveldrijenNaarTitel.bind(this)}
            />
          </Route>
        </Switch>
        {(!this.state.resultatenGezocht && this.state.bericht.length>0 &&
          this.props.location.pathname.indexOf("eenvoudig/") === -1 && this.props.location.pathname.indexOf("geavanceerd/") === -1) ?
          <div style={{marginLeft:10, marginTop : 20, padding:10, border:"1px solid"}}>
          <span dangerouslySetInnerHTML={{__html:this.state.bericht}} />
          </div>
        : ""}
      </div>
    );
  }
}

export default withRouter(Zoekformulier);