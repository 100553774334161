import React from 'react';
import ReactDOM from 'react-dom';
import Zoekformulier from './Zoekformulier';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import { BrowserRouter, Route } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontFamily: "'Hind', sans-serif",
    fontSize: '1rem',
    fontWeight: '400',
    color: '#3C1D64',
    lineHeight: '1.5'  
  },
  overrides: {

    MuiDialogContentText: {
      root: {
        color: "#C8004A"
      },
    },

    MuiButton: {
      root: {
        borderRadius: 0,
      }, 
    }, 
    MuiPaper: {
      root: {
        backgroundColor: "#FFFFFF"
      },
    },
    MuiAutocomplete: {
      input: {
        color: "#2691D2" //blue
      },
    },
    MuiInputBase: {
      root: {
        color: "#2691D2"
      },
    },
    MuiPopover: {
      root: {
        padding: '1.5rem',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '0',
      },
    },
    MuiPaginationItem: {
      outlinedPrimary: {
        border: "none",
        selected: {
          color: "#FC9200"
        }
      },
    },
    Mui: {
      root: {
        "&$selected": {
          border: "none"
        }
      }
    },
    typography: {
      button: {
        textTransform: "none"
      }
    }  
  },
  bla1: {
    width: 200
  }
});

window.theme = theme;

window.redSelectTheme= createTheme({
  overrides: {
    MuiSelect: { 
      root: { 
        color: "#C8004A" //red
      },
    },
  },
});

window.purpleSelectTheme= createTheme({
  overrides: {
    MuiSelect: { 
      root: { 
        color: "#3C1D64" //purple
      },
    },
  },
});

window.orangeTheme = createTheme({
  palette: {
    primary: {
      main: "#FC9200"
    }
  } 
});

window.blueTheme = createTheme({
  palette: {
    primary: {
      main: "#2691D2"
    }
  },
  typography: {
    a: {
      fontFamily: "'Hind', sans-serif",
      fontSize: "0.8rem",
      lineHeight: "1.5"
    }
  }
});

window.redTheme = createTheme({
  palette: {
    primary: {
        main: "#C8004A"
      }
     } 
  }
);

window.purpleTheme = createTheme({
  palette: {
      primary: {
        main: "#3C1D64"
      }
     } 
  }
);

window.tekstGrootTheme2 = createTheme({
  typography: {
    h1: {
      fontFamily: "Hind-Regular",
      fontSize: "1.375rem",
      lineHeight: "1.3",
      letterSpacing: "1.5px"
    }
  }
});

window.tekstGrootTheme = createTheme({
  palette: {
    text:{
      primary: "#3C1D64", //purple
      secondary: "#C8004A" //red
    }
  },
  typography: {
    h1: {
      fontFamily: "Hind-Regular",
      fontSize: "1.375rem",
      lineHeight: "1.3",
      letterSpacing: "1.5px"
    }
  }
});

window.tekstResultaatTheme = createTheme({
  palette: {
    text:{
      primary: "#3C1D64", //purple
      secondary: "#C8004A" //red
    }
  },
  typography: {
    body1: {
      fontFamily: "'Hind', sans-serif",
      fontSize: "0.8rem",
      lineHeight: "1.5"
    }
  }
});

window.tekstZoektermTheme = createTheme({
  palette: {
    text:{
      primary: "#3C1D64", //purple
      secondary: "#FC9200" //orange
    }
  },
  typography: {
    body1: {
      fontFamily: "'Hind', sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5"
    }
  }
});

window.selectieButtonTheme = createTheme({
  palette: {
    text: {
      primary: "#3C1D64", //purple
      secondary: "#2691D2" //blue
    },
  },
  typography: {
    body1: {
      fontFamily: "'Hind', sans-serif",
      fontSize: "1rem",
      lineHeight: "1"
    }
  }
});

window.tekstTitelTheme = createTheme({
  palette: {
    text:{
      primary: "#3C1D64", //purple
      secondary: "#C8004A" //red
    }
  },
  typography: {
    h1: {
      fontFamily: "'Hind', sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      fontWeight: "bold"
    }
  }
});

ReactDOM.render(
    <BrowserRouter>
      <Route path={[
        "/eenvoudig/zoeken/:termen/:onlineartikelen/:geenrecensies/:vervolgzoekactie/:sorteeroptie/:aantalperpagina/:pagina",
        "/eenvoudig/zoeken/:termen/:onlineartikelen/:geenrecensies",
        "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies/:vervolgzoekactie/:sorteeroptie/:aantalperpagina/:pagina",
        "/geavanceerd/zoeken/:rijen/:onlineartikelen/:geenrecensies",
        "/"]}>
        <ThemeProvider theme={theme}>
          <Zoekformulier/>
        </ThemeProvider>
      </Route>
    </BrowserRouter>,
  document.getElementById('root')
);