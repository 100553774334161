import React, { Fragment } from 'react';
import {Popover} from '@material-ui/core';
import { Button, FormControl, MenuItem, Select, InputLabel, FormLabel } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

class ZoekveldBijbelHoofdstukEnVers extends React.Component {

	render() {

		const hoofdstukOptions = [];

		let currentHoofdstuk = this.props.beginHoofdstuk ? this.props.beginHoofdstuk : 1;
		let currentVers = this.props.beginVers ? this.props.beginVers : 1;

		for(; currentHoofdstuk <= this.props.aantalHoofdstukken; currentHoofdstuk++) {
			hoofdstukOptions.push(<MenuItem key={this.props.bijbelboekId+"-"+currentHoofdstuk} value={currentHoofdstuk}>{currentHoofdstuk}</MenuItem>);
		}

		const versOptions = [];

		for(; currentVers <= this.props.aantalVerzen; currentVers++) {
			versOptions.push(<MenuItem key={this.props.bijbelboekId+"-"+this.props.hoofdstuk+"-"+currentVers} value={currentVers}>{currentVers}</MenuItem>);
		}

		return (
			<Fragment>						
				<FormControl>
					<InputLabel>Hoofdstuk</InputLabel>
          <MuiThemeProvider theme={window.purpleSelectTheme}>         
            <Select
              id={this.props.id + "-hoofdstuk"}
              onChange={this.props.onChangeHoofdstuk}
              value={this.props.hoofdstuk}
              style={{'minWidth': '80px'}}
              color="primary"
              >
              {hoofdstukOptions}
            </Select>
          </MuiThemeProvider>
				</FormControl>
				<FormControl>
					<InputLabel>Vers</InputLabel>
          <MuiThemeProvider theme={window.purpleSelectTheme}>         
            <Select
              id={this.props.id + "-vers"}
              onChange={this.props.onChangeVers}
              value={this.props.vers}
              style={{'minWidth': '40px'}}
              >
              {versOptions}
            </Select>
          </MuiThemeProvider>
				</FormControl>
			</Fragment>
		);
	}
}

class Bijbellocatie extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      bijbellocatie : props.zoekveldrij.veldWaarde != null ? props.zoekveldrij.veldWaarde : {
        bijbelboekId: 1,
        hoofdstukBegin: 1,
        versBegin : 1,
        hoofdstukEinde: 1,
        versEinde : 1
      },
      aantalHoofdstukken: 1,
			aantalVerzenBegin: 1,
      aantalVerzenEinde: 1,
      popBijbellocatieOpen: false,
			anchorElBijbellocatie: null
    }

    this.aantalAantalHoofdstukkenEnVerzenBepaald = false;
	}

  componentDidMount() {

    if(this.props.lijstBijbelboeken.length > 0){
      this.aantalAantalHoofdstukkenEnVerzenBepaald = true;
      this.bepaalAantalHoofdstukkenEnVerzen();
    }
  }

  componentDidUpdate() {

    if(!this.aantalAantalHoofdstukkenEnVerzenBepaald && this.props.lijstBijbelboeken.length > 0){
      this.aantalAantalHoofdstukkenEnVerzenBepaald = true;
      this.bepaalAantalHoofdstukkenEnVerzen();
    }
  }

  bepaalAantalHoofdstukkenEnVerzen() {

    this.bepaalAantalHoofdstukken(this.state.bijbellocatie.bijbelboekId);
    this.bepaalAantalVerzen("van", this.state.bijbellocatie.bijbelboekId, this.state.bijbellocatie.hoofdstukBegin);
    this.bepaalAantalVerzen("tot", this.state.bijbellocatie.bijbelboekId, this.state.bijbellocatie.hoofdstukEinde);
  }

  bepaalAantalHoofdstukken(bijbelboekId) {
  
    this.props.lijstBijbelboeken.forEach((bijbelboek) => {
      if (Number(bijbelboek.bijbelboekId)===Number(bijbelboekId)) {
        this.setState({aantalHoofdstukken: bijbelboek.aantalHoofdstukken});
      }
    })
  }

  bepaalAantalVerzen(vanOfTot, bijbelboekId, hoofdstuk) {

    this.props.lijstBijbelboeken.forEach((bijbelboek) => {
      if (Number(bijbelboek.bijbelboekId)===Number(bijbelboekId)) {
        var verzenPerHoofdstuk = bijbelboek.verzenPerHoofdstuk;
        verzenPerHoofdstuk.forEach((verzenPerHoofdstuk) => {
          if (Number(verzenPerHoofdstuk.hoofdstuk)===Number(hoofdstuk)) {
            if (vanOfTot==="van") {
              this.setState({aantalVerzenBegin: verzenPerHoofdstuk.verzen});
            }
            else {
              this.setState({aantalVerzenEinde: verzenPerHoofdstuk.verzen});
            }
          }
        })
      }
    })
  }


	handleChangeBijbelboek (event) {

		const bijbelboekId = event.target.value;
		const hoofdstuk = 1;
    const bijbellocatie = {
      bijbelboekId:bijbelboekId, 
      hoofdstukBegin:hoofdstuk,
      versBegin: 1,
      hoofdstukEinde: hoofdstuk,
      versEinde: 1
    }
		this.setState(
      {bijbellocatie: bijbellocatie}
		);
    this.bepaalAantalHoofdstukken(bijbelboekId);
    this.bepaalAantalVerzen("van", bijbelboekId, hoofdstuk);
    this.bepaalAantalVerzen("tot", bijbelboekId, hoofdstuk);
  }  
  
  handleChangeHoofdstuk(vanOfTot, event) {
    var bijbellocatie = this.state.bijbellocatie;
    var hoofdstuk = event.target.value;
    if(vanOfTot === 'van') {
      bijbellocatie.hoofdstukBegin = hoofdstuk;
      bijbellocatie.versBegin = 1;
      if (bijbellocatie.hoofdstukEinde<hoofdstuk) {
        bijbellocatie.hoofdstukEinde = hoofdstuk;
        bijbellocatie.versEinde = 1;  
        this.bepaalAantalVerzen("tot", bijbellocatie.bijbelboekId, hoofdstuk);
      }
      this.bepaalAantalVerzen("van", bijbellocatie.bijbelboekId, hoofdstuk);
    } else {
      bijbellocatie.hoofdstukEinde = hoofdstuk;
      this.bepaalAantalVerzen("tot", bijbellocatie.bijbelboekId, hoofdstuk);
      if (Number(bijbellocatie.hoofdstukEinde)===Number(bijbellocatie.hoofdstukBegin)) {
        bijbellocatie.versEinde=bijbellocatie.versBegin;
      }
      else {
        bijbellocatie.versEinde=1;
      }
    }
		this.setState(
      {bijbellocatie: bijbellocatie}
    );    
  }
  
  handleChangeVers(vanOfTot, event) {
    var bijbellocatie = this.state.bijbellocatie;
    var vers =event.target.value;
    if(vanOfTot === 'van') {
      bijbellocatie.versBegin = vers;
      if (Number(bijbellocatie.hoofdstukBegin)===Number(bijbellocatie.hoofdstukEinde)) {
        if (bijbellocatie.versEinde<vers) {
          bijbellocatie.versEinde = vers;
        }       
      }
    } else {
      bijbellocatie.versEinde = vers;
    }
		this.setState(
      {bijbellocatie: bijbellocatie}
		);
  }

  handleCloseBijbellocatie () {

    this.setState({
      popBijbellocatieOpen: false,
    });

    this.props.onChangeWaarde("veldWaarde-"+this.props.zoekveldrij.veldrijId, this.props.zoekveldrij.veldType, this.state.bijbellocatie);
  }

  handleOpenBijbellocatie(e) {
    e.preventDefault();
    this.setState({
      popBijbellocatieOpen: !this.state.popBijbellocatieOpen,
      anchorElBijbellocatie: e.currentTarget
    });
  }

  render() {

    //Bepaal tekst in button
    var buttonTekst = "";
    var buttonColor;
//    if (this.props.zoekveldrij.veldWaarde === null || this.state.bijbellocatie.bijbelboekId !== this.props.zoekveldrij.veldWaarde.bijbelboekId) {
    if (!this.state.popBijbellocatieOpen && this.props.zoekveldrij.veldWaarde === null) {
      buttonTekst="Selecteer bijbellocatie";
      buttonColor="textPrimary";
    }
    else {
      //Bepaal bijbellocatie drukknop
      this.props.lijstBijbelboeken.forEach((bijbelboek) => {
        if (Number(bijbelboek.bijbelboekId) === Number(this.state.bijbellocatie.bijbelboekId)) {
          buttonTekst = bijbelboek.afkorting;
          buttonTekst = buttonTekst + " " + this.state.bijbellocatie.hoofdstukBegin + ":" + this.state.bijbellocatie.versBegin + "-";
          if (Number(this.state.bijbellocatie.hoofdstukBegin) !== Number(this.state.bijbellocatie.hoofdstukEinde)) {
            buttonTekst = buttonTekst + this.state.bijbellocatie.hoofdstukEinde + ":";
          }
          buttonTekst = buttonTekst + this.state.bijbellocatie.versEinde;
        }
      });
      buttonColor="textSecondary";
    }

    // Bepaal beginVers van select-bijbelboek-tot
    var beginVers = 1;
    if (Number(this.state.bijbellocatie.hoofdstukBegin)===Number(this.state.bijbellocatie.hoofdstukEinde)) {
      beginVers = this.state.bijbellocatie.versBegin;
    }
      
    return (
      <div>
          <Button
            style={{textTransform: 'none', fontWeight: '400', height : 30}}
            id={"veldWaarde-"+this.props.zoekveldrij.veldrijId}
            type="submit" 
            onClick={this.handleOpenBijbellocatie.bind(this)} 
            >
            <MuiThemeProvider theme={window.selectieButtonTheme}>
              <Typography variant="body1" color={buttonColor}>{buttonTekst}</Typography>
            </MuiThemeProvider>
          </Button>
        <Popover
          open={this.state.popBijbellocatieOpen}
          anchorEl={this.state.anchorElBijbellocatie}
          className="popover_class"
          anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
          transformOrigin={{horizontal: 'center', vertical: 'top'}}
          onClose={this.handleCloseBijbellocatie.bind(this)}
        >
          <div style={{padding: '0.5rem'}}>
            <FormControl>
              <InputLabel>Bijbelboek</InputLabel>					
              <MuiThemeProvider theme={window.purpleSelectTheme}>         
                <Select
                  id="select-bijbelboek"
                  value={this.props.lijstBijbelboeken.length > 0 ? this.state.bijbellocatie.bijbelboekId : ''}
                  onChange={this.handleChangeBijbelboek.bind(this)}                              
                  style={{'minWidth': '120px'}}
                >
                  {this.props.lijstBijbelboeken.map((bijbelboek, ID) => (                         
                    <MenuItem key={ID} value={bijbelboek.bijbelboekId}>{bijbelboek.naam}</MenuItem>
                  ))}
                </Select>
              </MuiThemeProvider>
              </FormControl>
            <ZoekveldBijbelHoofdstukEnVers
              id="select-bijbelboek-van"
              aantalHoofdstukken={this.state.aantalHoofdstukken}
              aantalVerzen={this.state.aantalVerzenBegin}
              bijbelboekId={this.state.bijbellocatie.bijbelboekId}
              hoofdstuk={this.state.bijbellocatie.hoofdstukBegin}
              vers={this.state.bijbellocatie.versBegin}
              onChangeHoofdstuk={(event) => this.handleChangeHoofdstuk('van', event)}
              onChangeVers={(event) => this.handleChangeVers('van', event)}
              >
            </ZoekveldBijbelHoofdstukEnVers>
            <FormControl>
              <span>&nbsp;</span>
              <FormLabel>&nbsp;&nbsp;_&nbsp;&nbsp;</FormLabel>
            </FormControl>
            <ZoekveldBijbelHoofdstukEnVers
              id="select-bijbelboek-tot"
              aantalHoofdstukken={this.state.aantalHoofdstukken}
              aantalVerzen={this.state.aantalVerzenEinde}
              bijbelboekId={this.state.bijbellocatie.bijbelboekId}
              beginHoofdstuk={this.state.bijbellocatie.hoofdstukBegin}
              beginVers={beginVers}
              hoofdstuk={this.state.bijbellocatie.hoofdstukEinde}
              vers={this.state.bijbellocatie.versEinde}
              onChangeHoofdstuk={(event) => this.handleChangeHoofdstuk('tot', event)}
              onChangeVers={(event) => this.handleChangeVers('tot', event)}
              >
            </ZoekveldBijbelHoofdstukEnVers>
          </div>
        </Popover>
      </div>
    );
  }
}
export default Bijbellocatie;
