import React from 'react';
import {Popover, Button} from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

class Categorie extends React.Component {

  constructor(props) {
    super(props);
    this.aantalRenders = 0;
    this.state = {
      error: null,
      popCategorieOpen: false,
			anchorElCategorie: null,
      categorieId: props.zoekveldrij.veldWaarde
    }
	}

  handleCloseCategorie () {

    this.setState({
      popCategorieOpen: false,
    });

    this.props.onChangeWaarde("veldWaarde-"+this.props.zoekveldrij.veldrijId, this.props.zoekveldrij.veldType, this.state.categorieId);
  }

  handleOpenCategorie(e) {
    e.preventDefault();
    this.setState({
      popCategorieOpen: !this.state.popCategorieOpen,
      anchorElCategorie: e.currentTarget
    });
  }

  handleNodeSelect (event, nodeIds) {
    event.preventDefault();
    if (event.target.innerText!=null) {
      this.setState ({
        categorieId : nodeIds,
      });
    }
  }

  render() {

    //Bepaal tekst in button
    var buttonTekst = "";
    var buttonColor;

    if (!this.state.popCategorieOpen && this.props.zoekveldrij.veldWaarde==='') {
      buttonColor="primary";
      buttonTekst="Selecteer categorie";
    }
    else {
      if (this.props.zoekveldrij.veldWaarde !=null) {
        this.props.lijstCategorieen.forEach((categorie) => {
          if (Number(categorie.categorieId)===Number(this.state.categorieId)) {
            buttonTekst=categorie.categorie;
            buttonColor="textSecondary";
          }
        })
      }
    }

    /*if (buttonTekst === "") {
      buttonColor="textPrimary";
      buttonTekst="Selecteer categorie";
    }*/

    var treeItems= this.props.lijstCategorieen.map((categorie) => {
      if (Number(categorie.parentId)===-1) {
        return (
          <TreeItem nodeId={categorie.categorieId} label={categorie.categorie} key={categorie.categorieId}>
            {this.props.lijstCategorieen.map((subcategorie) => {
              if(Number(subcategorie.parentId) === Number(categorie.categorieId)) {
                return (
                  <TreeItem nodeId={subcategorie.categorieId} label={subcategorie.categorie} key={subcategorie.categorieId}/>                  
                )
              }
              else {
                return null
              }
            })}
          </TreeItem>
        );
      }
      else {
        return null
      }
    });

    return (
      <div>
          <Button
            style={{textTransform: 'none', fontWeight: '400', height : 30}}
            id={"veldWaarde-"+this.props.zoekveldrij.veldrijId}
            type="submit"
            color={buttonColor}
            onClick={this.handleOpenCategorie.bind(this)} 
            >
            <MuiThemeProvider theme={window.selectieButtonTheme}>
              <Typography variant="body1" color={buttonColor}>{buttonTekst}</Typography>
            </MuiThemeProvider>
          </Button>
        <Popover
          open={this.state.popCategorieOpen}
          anchorEl={this.state.anchorElCategorie}
          className="popover_class"
          anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
          transformOrigin={{horizontal: 'left', vertical: 'top'}}
          onClose={this.handleCloseCategorie.bind(this)}
          style={{padding: '10px'}}
        >
          <div style={{padding: '0.5rem'}}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeSelect={this.handleNodeSelect.bind(this)}
              selected={this.props.zoekveldrij.veldWaarde}
            >
              {treeItems}
            </TreeView>
          </div>
        </Popover>
      </div>
    );
  }
}
//{this.props.lijstCategorieen.map((node) => renderTree(node))}
export default Categorie;
