import React from 'react';
import CancelIcon from "@material-ui/icons/Cancel";
import {IconButton} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class Foutmelding extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen : true,
    }
  }

  componentDidMount() {
    this.setState ({
      dialogOpen: true,
    });  
  }

  handleCloseDialog () {
    this.setState ({
      dialogOpen: false,
    });
  }

  render() {

    return (
      <div >
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.foutmelding}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton 
            onClick={this.handleCloseDialog.bind(this)}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      </div>
    );
  }
}
export default Foutmelding;