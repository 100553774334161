import React from 'react';
import { Paper, Grid, IconButton, Tooltip, Popover, TextField, Hidden, Link } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import CancelIcon from "@material-ui/icons/Cancel";
import FeedbackIcon from '@material-ui/icons/Feedback';
import SendIcon from '@material-ui/icons/Send';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Foutmelding from './Foutmelding';
import ReCAPTCHA from "react-google-recaptcha";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


class ZoekResultaat extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorMessage: "",
      popoverFeedbackOpen: false,
      anchorElFeedback: null,
      popoverVerzondenOpen: false,
      anchorElVerzonden: null,
      feedback: "",
      ReCAPTCHAchecked: false,
      databaseActiviteit: false
    }
  }

  handleOpenPopover(event) {
    this.setState({
      popoverFeedbackOpen: true,
      anchorElFeedback: event.currentTarget,
      feedback: ""
    });
  }

  handleChangeFeedback(event) {
    var feedback = event.target.value;
    if (feedback.length <= 200) {
      this.setState({
        feedback: feedback
      });
    }
  }

  handleOnclickSend() {
    this.setState({
      popoverFeedbackOpen: false,
      anchorElFeedback: null,
      anchorElVerzonden: this.state.anchorElFeedback
    });
    if (this.state.feedback.length > 0) {
      this.addFeedback(this.state.feedback, this.props.zoekresultaat.artikelId, this.feedbackVerzonden.bind(this));
    }
  }

  handleOnclickCancel() {
    this.setState({
      popoverFeedbackOpen: false,
      anchorElFeedback: null
    });
  }

  feedbackVerzonden() {
    this.setState({
      popoverVerzondenOpen: true,
    });

    this.timeout = setTimeout(() => {
      this.setState({
        popoverVerzondenOpen: false,
      }, () => {
        this.timeout = null;
      });
    }, 2000);

  }

  handleChangeReCAPTCHA(value) {
    this.setState({
      ReCAPTCHAchecked: true
    })
  }

  addFeedback(feedback, artikelId, onReady) {
    //Functie schrift feedback naar database

    //Stel data samen
    var data = { feedback: feedback, artikelId: artikelId };

    var json = JSON.stringify(data);

    this.setState({ 
      error: false,
      errorMessage: "",
      databaseActiviteit: true 
    });

    //Bepaal clientsleutel
    var clientSleutel;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      clientSleutel = "qfOORX9YyFxkGA5SbK9MiCP9Vczdu0z8";
    } else {
      clientSleutel = window.client_sleutel;
    }

    //Bepaal controlecode voor feedback/create
    const serviceNummer = 3568;
    var randomGetal = Math.floor(Math.random() * 10000); //range 0-9999
    if (randomGetal<1000) randomGetal += 1000;
    var controleGetal = json.length * serviceNummer * randomGetal;
    var controleCode = randomGetal.toString() + controleGetal.toString();

    fetch("/api/feedback/create.php?d=" + Date.now()+"&c="+controleCode+"&s="+clientSleutel, { method: 'POST', body: json })
    .then(res => res.json())
    .then(
      (result) => {
        if (typeof result.message != "undefined") {
          if (result.message.substr(0, 4)=== "Fout") {
            this.setState({
              error: true,
              errorMessage: "Er gaat iets mis bij het verzenden van de feedback.",
              databaseActiviteit: false
            });        
          }
          else {
            this.setState({
              databaseActiviteit: false
            });        
            onReady();
          }
        }
        else {
          this.setState({
            error: true,
            errorMessage: "Er gaat iets mis bij het verzenden van de feedback.",
            databaseActiviteit: false
          });
        }
      },
      (error) => {
        this.setState({
          error: true,
          errorMessage: "Er gaat iets mis bij het verzenden van de feedback.",
          databaseActiviteit: false
        });
      }
    );
  }

  handleExport(){
    var artikelIds = [this.props.zoekresultaat.artikelId];
    this.props.fetchExport(artikelIds);
  }

  render() {
    var zoekresultaat = this.props.zoekresultaat;
    var bestandAfbeelding = "/afbeelding/" + zoekresultaat.naamBestandAfbeelding;
    // Stel bron samen
    var jaargang;
    var nummer;
    var paginaStart;
    var paginaEinde;
    var jaar;
    if (zoekresultaat.jaargang !=null) {
      jaargang = zoekresultaat.jaargang.length > 0 ? zoekresultaat.jaargang : "";
    } else {
      jaargang="";
    }
    if (zoekresultaat.nummer !=null) {
      nummer = zoekresultaat.nummer.length > 0 ? "nr " + zoekresultaat.nummer : "";
    } else {
      nummer="";
    }
    if (zoekresultaat.paginaStart !=null) {
      paginaStart = zoekresultaat.paginaStart.length > 0 ? zoekresultaat.paginaStart : null;
    } else {
      paginaStart = null;
    }
    if (zoekresultaat.paginaEinde !=null) {
      paginaEinde = zoekresultaat.paginaEinde.length > 0 ? zoekresultaat.paginaEinde : null;
    } else {
      paginaEinde = null;
    }
    var pagina = "";
    if (paginaStart !== null) {
      pagina = ", p " + paginaStart;
      if (paginaEinde !== null) {
        pagina = pagina + "-" + paginaEinde
      }
    }
    else {
      if (paginaEinde !== null) {
        pagina = ", p " + paginaEinde;
      }
    }
    if (zoekresultaat.jaar !=null) {
      jaar = zoekresultaat.jaar.length > 0 ? zoekresultaat.jaar : "";
    } else {
      jaar="";
    }
    var bron = <span>{jaargang} {nummer} {jaar}{pagina}</span>;

    //Stel url naar tekst artikel samen
    var urlRoot = "";
    var urlBestand = "";
    if (zoekresultaat.urlRoot != null) {
      urlRoot = zoekresultaat.urlRoot.length > 0 ? zoekresultaat.urlRoot : "";
    }
    if (zoekresultaat.urlBestand != null) {
      urlBestand = zoekresultaat.urlBestand.length > 0 ? zoekresultaat.urlBestand : "";
    }
    var url = "";
    if (urlRoot !== "" && urlBestand !== "") {
      url = urlRoot + urlBestand;
    }
    else {
      if (urlBestand !== "") {
        url = urlBestand;
      }
    }
    
    var volledigeTekst = "";
    if (url !== "") {
        volledigeTekst =
        <MuiThemeProvider theme={window.blueTheme}>
          <Link href={url} underline="none" target="_blank" variant="body2">
              <LinkIcon /> {'Bekijk volledige artikel'}
          </Link>
        </MuiThemeProvider>
    }

    var linkTijdschrift = '/tijdschriften_item.php?tijdschriftId=' + zoekresultaat.tijdschriftId;

    return (
      <div style={{ marginTop: 20 }}>
        {this.state.error ? 
          <Foutmelding
            foutmelding={this.state.errorMessage}
          /> 
          : ""
        }
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "5%" }}>
                <MuiThemeProvider theme={window.tekstGrootTheme}>
                  <Typography variant="h1" color="textPrimary">{this.props.nummerZoekresultaat}</Typography>
                </MuiThemeProvider>
              </td>
              <td>
                <Paper style={{ padding: 20 }}>
                  <Grid container spacing={2}>
                    <Grid item md={1} sm={2} xs={12}>
                      <Link href={process.env.PUBLIC_URL + linkTijdschrift} underline="none" target="_self">
                        <img src={bestandAfbeelding} alt="" width="45" height="33" />
                      </Link>
                    </Grid>
                    <Grid item md={9} sm={8}>
                      <MuiThemeProvider theme={window.tekstTitelTheme}>
                        <Typography variant="h1" color="textPrimary">{zoekresultaat.titel}</Typography>
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item md={2} sm={2}>
                      <Hidden xsDown>
                        <MuiThemeProvider theme={window.purpleTheme}>
                          <Tooltip title="RIS Export" placement="right" arrow>
                            <IconButton
                              onClick={this.handleExport.bind(this)}
                              style={{ paddingLeft: 10, paddingRight: 0, paddingTop:0, paddingBottom: 0, float:"right"}}
                              color="primary"
                            >
                              <ExitToAppIcon />
                            </IconButton>
                          </Tooltip>
                        </MuiThemeProvider>
                        <MuiThemeProvider theme={window.purpleTheme}>
                          <Tooltip title="Feedback" placement="right" arrow>
                            <IconButton
                              onClick={this.handleOpenPopover.bind(this)}
                              style={{ paddingLeft: 0, paddingRight: 0, paddingTop:0, paddingBottom: 0, float: "right"}}
                              color="primary"
                            >
                              <FeedbackIcon />
                            </IconButton>
                          </Tooltip>
                        </MuiThemeProvider>
                        <Popover
                          open={this.state.popoverFeedbackOpen}
                          anchorEl={this.state.anchorElFeedback}
                          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                          <div style={{ marginTop: 10, width: 370, marginLeft: 20, paddingRight: 20}}>
                            <span>Klopt er iets niet in deze gegevens? Laat het ons weten, dan passen wij het aan.</span>
                          </div>
                          <TextField
                            id="Feedback"
                            value={this.state.feedback}
                            onChange={this.handleChangeFeedback.bind(this)}
                            multiline
                            rowsMax="4"
                            style={{ marginLeft: 20, width: 350 }}
                          />
                          <div style={{ marginTop: 20 }} />
                          <span style={{ marginLeft: 20 }}>Nog {200 - this.state.feedback.length} letters te gaan.</span>
                          <div style={{ marginTop: 20, marginLeft: 20}}>
                            <ReCAPTCHA
                              sitekey="6LdySH4bAAAAAMg_DABSI4Nxt67hlvU5P8NZtoRu"
                              onChange={this.handleChangeReCAPTCHA.bind(this)}
                            />
                          </div>
                          <MuiThemeProvider theme={window.purpleTheme}>
                            <Tooltip title="Verzenden" placement="top" arrow>
                              <span>
                                <IconButton
                                  onClick={this.handleOnclickSend.bind(this)}
                                  style={{ marginLeft: 280, align: "right" }}
                                  color="primary"
                                  disabled={!this.state.ReCAPTCHAchecked}
                                >
                                  <SendIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </MuiThemeProvider>
                          <MuiThemeProvider theme={window.purpleTheme}>
                            <Tooltip title="Annuleren" placement="top" arrow>
                              <IconButton
                                onClick={this.handleOnclickCancel.bind(this)}
                                style={{ marginLeft: 0 }}
                                color="primary"
                              >
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          </MuiThemeProvider>
                        </Popover>
                        <Popover
                          open={this.state.popoverVerzondenOpen}
                          anchorEl={this.state.anchorElVerzonden}
                          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                          <div style={{ padding: 15 }}>
                            {<span style={{ paddingLeft: 10 }}><b>Uw opmerking is verzonden. Dank voor uw medewerking.</b></span>}
                          </div>
                        </Popover>
                      </Hidden>
                    </Grid>
                    <Grid item xs={false} sm={2} md={1}>
                    </Grid>
                    <Grid item xs={12} sm={10} md={11}>
                      <Grid container>
                        <Grid item sm={5} xs={12}>
                          <Hidden xsDown>
                            <div style={{
                                    display: 'flex',
                                }}>
                              <MuiThemeProvider theme={window.purpleTheme}>
                                <PersonIcon color="primary" style={{ marginLeft: -5, paddingBottom: 5, verticalAlign: "top"}} />
                              </MuiThemeProvider>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textSecondary"><span style={{ paddingRight: 20 }}>{zoekresultaat.auteurs}</span></Typography>
                              </MuiThemeProvider>
                            </div>
                            <div style={{
                                    display: 'flex',
                                }}>
                              <MuiThemeProvider theme={window.purpleTheme}>
                                <DescriptionIcon color="primary" style={{ marginLeft: -5, paddingRight: 1, verticalAlign: "top",  height: "20px" }} />
                              </MuiThemeProvider>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Hidden smDown>
                                  <Typography color="textSecondary"><span style={{ paddingRight: 20 }}>{zoekresultaat.tijdschrift} {bron}</span></Typography>
                                </Hidden>
                                <Hidden mdUp>
                                  <Typography color="textSecondary">{zoekresultaat.tijdschrift}<br/> {bron}</Typography>
                                </Hidden>
                              </MuiThemeProvider>
                            </div>
                          </Hidden>
                          <Hidden smUp>
                            <Grid container>
                              <Grid item xs={6}>
                                <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                  <Typography color="textPrimary">Auteurs:</Typography>
                                </MuiThemeProvider>                                
                              </Grid>
                              <Grid item xs={6}>
                                <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                  <Typography color="textSecondary">{zoekresultaat.auteurs}</Typography>
                                </MuiThemeProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                  <Typography color="textPrimary">Tijdschrift:</Typography>
                                </MuiThemeProvider>                                
                              </Grid>
                              <Grid item xs={6}>
                                <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                  <Typography color="textSecondary">{zoekresultaat.tijdschrift} {bron}</Typography>
                                </MuiThemeProvider>
                              </Grid>
                            </Grid>
                          </Hidden>
                        </Grid>
                        <Grid item sm={7} xs={12}>
                          <Grid container>
                            <Grid item xs={6} md={4}>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textPrimary">Categorieën:</Typography>
                              </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={6} md={8}>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textSecondary">{zoekresultaat.categorieen}</Typography>
                              </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textPrimary">Sleutelwoorden:</Typography>
                              </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={6} md={8}>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textSecondary">{zoekresultaat.sleutelwoorden}</Typography>
                              </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textPrimary">Bijbelboeken:</Typography>
                              </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={6} md={8}>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textSecondary">{zoekresultaat.bijbelboeken}</Typography>
                              </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textPrimary">Bijbellocaties:</Typography>
                              </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={6} md={8}>
                              <MuiThemeProvider theme={window.tekstResultaatTheme}>
                                <Typography color="textSecondary">{zoekresultaat.bijbellocaties}</Typography>
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={12} style={{height: 25, paddingTop: 8}}>
                          {volledigeTekst}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
export default ZoekResultaat;